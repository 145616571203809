import { LandingPage } from '../../../../components/LandingPage'
import Text from '../../../../components/BaseComponents/Text'
import { useParams } from 'react-router-dom'
import { Alert } from '../../../../components/BaseComponents'
import './styles.scss'

export const FinalReviewIntro = () => {
  const { year } = useParams()

  return (
    <LandingPage
      imageSrc="https://heard-images.s3.amazonaws.com/assets/checklist-confetti.svg"
      imageAlt="checklistConfetti"
      imageWidth={300}
      imageHeight={300}
      title="You're almost done!"
    >
      <div className="text-wrapper">
        <Text style={{ marginTop: '16px' }}>
          The bookkeeping team has completed your {year} bookkeeping. Please
          review and sign off to close your books for the year.
        </Text>

        <Text as="display3" style={{ marginTop: '16px' }}>
          What&apos;s next?
        </Text>

        <div className="listWrapper">
          <Text style={{ marginTop: '16px', lineHeight: 1.7 }}>
            <ul>
              <li>Look over your {year} income and expenses.</li>
              <li>
                If something still looks incorrect, leave a note so our team can
                address it.
              </li>
              <li>Otherwise, sign off and finalize your books for the year.</li>
            </ul>
          </Text>
        </div>

        <div className="alert-footer">
          <Alert
            type="info"
            title="Why is this important?"
            style={{ width: '520px' }}
            contentStyle={{ textAlign: 'left' }}
          >
            We want to ensure that your books are accurate and done to your
            satisfaction. This is an opportunity for you to provide corrections
            before closing your books.
          </Alert>
        </div>
      </div>
    </LandingPage>
  )
}
