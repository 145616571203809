import { useEffect } from 'react'
import { Grid, Image, List } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { INCOME_LIABILITIES_SCREENS, IncomeAndLiabilitiesStepProps } from '.'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import {
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { useTiles } from '../../Shared/UseTiles'
import { rentalPropertyQuestionIds } from './RentalPropertyPanel'
import { investmentsQuestionIds } from './InvestmentsPanel'
import { retirementContributedQuestionIds } from './RetirementContributedPanel'
import {
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import useProgressSteps from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import useMissingAnswerValidation from '../../Shared/UseMissingAnswerValidation'

export const IncomeAndLiabilitiesQuestionIds = [
  TaxListQuestionId.own_rental_property,
  TaxListQuestionId.own_investments,
  TaxListQuestionId.retirement_contributed,
]

const IncomeAndLiabilitiesPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form = useReselector(select1040FormForYear, taxYear)
  const formId = form?.id
  const dispatch = useAppDispatch()
  const { fetchKeys: progressFetchKeys } = useProgressSteps()
  const { errorAlert, clearError, triggerError } = useMissingAnswerValidation(
    'Please check at least one option. If you’re not sure, click "Request follow-up"'
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    ...progressFetchKeys,
  ])

  const ownRentalPropertyQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.own_rental_property,
  })

  const ownInvestmentsQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.own_investments,
  })

  const retirementContributedQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.retirement_contributed,
  })

  const rentalPropertyFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    rentalPropertyQuestionIds,
    formId
  )
  const investmentsFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    investmentsQuestionIds.filter(
      (qId) =>
        qId !== TaxListQuestionId.number_of_brokerage_statements &&
        qId !== TaxListQuestionId.received_dividends
    ), // Taxfyle always requires these questions so don't clear them
    formId
  )
  const retirementContributedFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    retirementContributedQuestionIds,
    formId
  )
  const brokerageStatementResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    [TaxListQuestionId.number_of_brokerage_statements],
    formId
  )
  const receivedDividendsResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    [TaxListQuestionId.received_dividends],
    formId
  )

  const responseNumberProperties = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_rental_properties,
    formId
  )

  const {
    tiles: selections,
    setTiles,
    noneApplySelected,
    tilesToResponses,
    anyResponseSelected,
    tileSelections,
    noneSelection,
  } = useTiles({ tileHeight: 180 })

  useEffect(() => {
    // This is an initial set - only set if tiles aren't set yet and questions are loaded
    if (
      selections.length ||
      !ownInvestmentsQR.loaded ||
      !ownRentalPropertyQR.loaded ||
      !retirementContributedQR.loaded
    ) {
      return
    }

    setTiles([
      {
        icon: light('house-building'),
        text: ownRentalPropertyQR.label,
        questionId: TaxListQuestionId.own_rental_property,
        response: ownRentalPropertyQR.firstResponse,
      },
      {
        icon: light('display-chart-up-circle-dollar'),
        text: ownInvestmentsQR.label,
        subtext: '(Stocks, bonds, investment properties)',
        questionId: TaxListQuestionId.own_investments,
        response: ownInvestmentsQR.firstResponse,
      },
      {
        icon: light('island-tropical'),
        text: retirementContributedQR.label,
        popup: {
          title: 'Applicable retirement plans',
          body: (
            <List as="ul">
              <List.Item as="li">Roth IRA</List.Item>
              <List.Item as="li">Traditional IRA</List.Item>
              <List.Item as="li">SEP IRA</List.Item>
              <List.Item as="li">Simple IRA</List.Item>
              <List.Item as="li">Solo 401(k)</List.Item>
            </List>
          ),
        },
        questionId: TaxListQuestionId.retirement_contributed,
        response: retirementContributedQR.firstResponse,
      },
    ])
  }, [
    ownInvestmentsQR.firstResponse,
    ownInvestmentsQR.label,
    ownInvestmentsQR.loaded,
    ownRentalPropertyQR.firstResponse,
    ownRentalPropertyQR.label,
    ownRentalPropertyQR.loaded,
    retirementContributedQR.firstResponse,
    retirementContributedQR.label,
    retirementContributedQR.loaded,
    selections.length,
    setTiles,
  ])

  const saveAndContinue = useAsyncCallback(async () => {
    clearError()
    const responseData: Partial<TaxChecklistResponse>[] = []
    const completedSteps = [SubStepIdentifiers.incomeAndLiabilities]
    const incompleteSteps: SubStepIdentifiers[] = []
    // if screen conditions change, make sure to also update in IncomeAndLiabilities/index
    const didOwnRentalProperty =
      selections.find(
        (s) => s.questionId === TaxListQuestionId.own_rental_property
      )?.response?.value === true
    const rentalPropertyFollowUpIds =
      rentalPropertyFollowUpQuestionResponses.map((r) => r.id)

    const didOwnInvestments =
      selections.find((s) => s.questionId === TaxListQuestionId.own_investments)
        ?.response?.value === true
    const ownInvestmentsFollowUpIds = investmentsFollowUpQuestionResponses.map(
      (r) => r.id
    )

    const didContributeToRetirement =
      selections.find(
        (s) => s.questionId === TaxListQuestionId.retirement_contributed
      )?.response?.value === true
    const retirementContributedFollowUpIds =
      retirementContributedFollowUpQuestionResponses.map((r) => r.id)

    const idsToDelete: number[] = []
    if (!didOwnRentalProperty) {
      if (rentalPropertyFollowUpIds.length > 0) {
        idsToDelete.push(...rentalPropertyFollowUpIds)
      }
      if (responseNumberProperties?.[0]?.value !== 0) {
        responseData.push({
          id: responseNumberProperties?.[0]?.id,
          value: 0,
          annualTaxFilingFormId: formId,
          questionId: TaxListQuestionId.number_of_rental_properties,
        })
      }
    }
    if (!didOwnInvestments) {
      if (ownInvestmentsFollowUpIds.length) {
        idsToDelete.push(...ownInvestmentsFollowUpIds)
      }
      if (brokerageStatementResponses?.[0]?.value !== 0) {
        responseData.push({
          id: brokerageStatementResponses?.[0]?.id,
          value: 0,
          annualTaxFilingFormId: formId,
          questionId: TaxListQuestionId.number_of_brokerage_statements,
        })
      }
      if (receivedDividendsResponses?.[0]?.value !== false) {
        responseData.push({
          id: receivedDividendsResponses?.[0]?.id,
          value: false,
          annualTaxFilingFormId: formId,
          questionId: TaxListQuestionId.received_dividends,
        })
      }
    }
    if (!didContributeToRetirement && retirementContributedFollowUpIds.length) {
      idsToDelete.push(...retirementContributedFollowUpIds)
    }
    if (idsToDelete.length > 0) {
      const deletionSucceeded = Boolean(
        await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
      )

      if (!deletionSucceeded) {
        return
      }
    }

    //process progresses for the step and substeps

    //there should always be a completed progress for the first tile page upon submission
    if (didOwnRentalProperty) {
      if (!rentalPropertyFollowUpIds.length) {
        incompleteSteps.push(SubStepIdentifiers.rentalProperties)
      }
    } else {
      completedSteps.push(SubStepIdentifiers.rentalProperties)
    }

    if (didOwnInvestments) {
      if (!ownInvestmentsFollowUpIds.length) {
        incompleteSteps.push(SubStepIdentifiers.investments)
      }
    } else {
      completedSteps.push(SubStepIdentifiers.investments)
    }

    if (didContributeToRetirement) {
      if (!retirementContributedFollowUpIds.length) {
        incompleteSteps.push(SubStepIdentifiers.retirement)
      }
    } else {
      completedSteps.push(SubStepIdentifiers.retirement)
    }

    let nextScreen = INCOME_LIABILITIES_SCREENS.lessCommonScenarios
    if (didOwnRentalProperty) {
      nextScreen = INCOME_LIABILITIES_SCREENS.rentalProperty
    } else if (didOwnInvestments) {
      nextScreen = INCOME_LIABILITIES_SCREENS.investments
    } else if (didContributeToRetirement) {
      nextScreen = INCOME_LIABILITIES_SCREENS.retirementContributed
    }
    goToNextStep([...tilesToResponses(), ...responseData], nextScreen ?? null, {
      completedSteps,
      incompleteSteps,
    })
  })

  return (
    <Grid>
      <GridRowColumn centerContent {...tqBigSpace}>
        <Image src="https://heard-images.s3.amazonaws.com/assets/chart-tablet.svg" />
      </GridRowColumn>
      <GridRowColumn {...tqBigSpace}>
        <Text as="display2" textAlign="center">
          Income and Liabilities
        </Text>
      </GridRowColumn>
      {errorAlert}
      <GridRowColumn {...tqSmallSpace}>
        <Text as="bodyLg" textAlign="center">
          Let us know if any of these applied to you in {taxYear}, so we can
          personalize the questionnaire for you.
        </Text>
      </GridRowColumn>
      {tileSelections}
      {noneSelection}
      <Grid.Row />
      <FormFlowFooter
        continueDisabled={saveAndContinue.loading}
        onBack={() => goBack(previousScreen ?? null)}
        onForward={
          !noneApplySelected && !anyResponseSelected
            ? triggerError
            : saveAndContinue.callback
        }
        loading={isUpdating || saveAndContinue.loading}
      />
    </Grid>
  )
}

export default IncomeAndLiabilitiesPanel
