import { useEffect } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import {
  DEDUCTIONS_AND_CREDITS_SCREENS,
  DeductionsAndCreditsStepProps,
} from '.'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectTaxListQuestionResponsesByQuestionIds } from '../../taxChecklist.selectors'
import { useTiles } from '../../Shared/UseTiles'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../../../annualTaxFilingForms.slice'
import { fetchUserEoyReviewProgress } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import {
  DEDUCTIONS_AND_CREDITS,
  SubStepIdentifiers,
} from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { fetchAllEoyReviewStepsIfNeeded } from '../../Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import { ownedHomeQuestionIds } from './OwnedHomePanel'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  deleteTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { businessMileageQuestionIds } from './HadBusinessMileagePanel'
import { charitableContributionsQuestionIds } from './CharitableContributionsPanel'
import { educationQuestionIds } from './EducationPanel'
import { dependentCareQuestionIds } from './DependentCareExpensesPanel'
import { homeOfficeQuestionIds } from './HomeOfficePanel'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import useProgressSteps from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import useMissingAnswerValidation from '../../Shared/UseMissingAnswerValidation'

export const deductionsAndCreditsQuestionIds = [
  TaxListQuestionId.owned_home,
  TaxListQuestionId.worked_from_home,
  TaxListQuestionId.had_business_mileage,
  TaxListQuestionId.owned_farm,
  TaxListQuestionId.had_college_students,
  TaxListQuestionId.paid_dependent_expenses,
  TaxListQuestionId.charitable_contribution,
]

const DeductionsAndCreditsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form = useReselector(select1040FormForYear, taxYear)
  const formId = form?.id
  const dispatch = useAppDispatch()
  const { errorAlert, clearError, triggerError } = useMissingAnswerValidation(
    'Please check at least one option. If you’re not sure, click "Request follow-up"'
  )

  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: DEDUCTIONS_AND_CREDITS }
  )

  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
    ...progressFetchKeys,
  ])

  const ownedHomeQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.owned_home,
  })

  const ownedHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    ownedHomeQuestionIds.filter(
      (qId) => qId !== TaxListQuestionId.paid_mortgage_interest
    ), // owned home is always required by TF so don't delete
    formId
  )

  const workedFromHomeQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.worked_from_home,
  })

  const workedFromHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    homeOfficeQuestionIds,
    formId
  )

  const hadBusinessMilageQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.had_business_mileage,
  })

  const businessMileageFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    businessMileageQuestionIds,
    formId
  )

  const ownedFarmQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.owned_farm,
  })

  const hadCollegeStudentsQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.had_college_students,
  })

  const hadCollegeStudentsFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    educationQuestionIds,
    formId
  )

  const paidDependentExpensesQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.paid_dependent_expenses,
  })

  const paidDependentExpensesFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    dependentCareQuestionIds,
    formId
  )

  const charitableContributionsQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.charitable_contribution,
  })

  const charitableContributionsFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    charitableContributionsQuestionIds,
    formId
  )

  const paidMortgageInterestQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.paid_mortgage_interest,
  })

  const numberOfFarmsQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.number_of_farms,
  })

  const {
    tiles: selections,
    setTiles,
    tilesToResponses,
    anyResponseSelected,
    tileSelections,
    noneSelection,
    noneApplySelected,
  } = useTiles({})

  useEffect(() => {
    if (selections.length || !ownedHomeQR.loaded) {
      return
    }

    setTiles([
      {
        icon: light('house'),
        text: ownedHomeQR.label,
        questionId: TaxListQuestionId.owned_home,
        response: ownedHomeQR.firstResponse,
      },
      {
        icon: light('chair-office'),
        text: workedFromHomeQR.label,
        questionId: TaxListQuestionId.worked_from_home,
        response: workedFromHomeQR.firstResponse,
      },
      {
        icon: light('car'),
        text: hadBusinessMilageQR.label,
        questionId: TaxListQuestionId.had_business_mileage,
        response: hadBusinessMilageQR.firstResponse,
      },
      {
        icon: light('tractor'),
        text: ownedFarmQR.label,
        questionId: TaxListQuestionId.owned_farm,
        response: ownedFarmQR.firstResponse,
      },
      {
        icon: light('graduation-cap'),
        text: hadCollegeStudentsQR.label,
        questionId: TaxListQuestionId.had_college_students,
        response: hadCollegeStudentsQR.firstResponse,
      },
      {
        icon: light('baby'),
        text: paidDependentExpensesQR.label,
        questionId: TaxListQuestionId.paid_dependent_expenses,
        response: paidDependentExpensesQR.firstResponse,
      },
      {
        icon: light('gift'),
        text: charitableContributionsQR.label,
        questionId: TaxListQuestionId.charitable_contribution,
        response: charitableContributionsQR.firstResponse,
      },
    ])
  }, [
    charitableContributionsQR,
    hadBusinessMilageQR,
    hadCollegeStudentsQR,
    numberOfFarmsQR.loaded,
    ownedFarmQR,
    ownedHomeQR,
    paidDependentExpensesQR,
    paidMortgageInterestQR,
    selections.length,
    setTiles,
    workedFromHomeQR,
  ])

  const saveAndContinue = useAsyncCallback(async () => {
    clearError()

    try {
      const responseData: Partial<TaxChecklistResponse>[] = []
      const didOwnHome =
        selections.find((s) => s.questionId === TaxListQuestionId.owned_home)
          ?.response?.value === true
      const ownedHomeFollowupIds = ownedHomeFollowUpQuestionResponses.map(
        (r) => r.id
      )

      const didWorkFromHome =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.worked_from_home
        )?.response?.value === true
      const workedFromHomeFollowupIds =
        workedFromHomeFollowUpQuestionResponses.map((r) => r.id)

      const didHaveBusinessMileage =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.had_business_mileage
        )?.response?.value === true
      const hadBusinessMileageFollowupIds =
        businessMileageFollowUpQuestionResponses.map((r) => r.id)

      const didOwnFarm =
        selections.find((s) => s.questionId === TaxListQuestionId.owned_farm)
          ?.response?.value === true

      const didHaveCollegeStudents =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.had_college_students
        )?.response?.value === true
      const hadCollegeStudentsFollowupIds =
        hadCollegeStudentsFollowUpQuestionResponses.map((r) => r.id)

      const didPayDependentCareExpenses =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.paid_dependent_expenses
        )?.response?.value === true
      const didPayDependentCareExpensesFollowupIds =
        paidDependentExpensesFollowUpQuestionResponses.map((r) => r.id)

      const didHaveCharitableContribution =
        selections.find(
          (s) => s.questionId === TaxListQuestionId.charitable_contribution
        )?.response?.value === true
      const charitableContributionsFollowupIds =
        charitableContributionsFollowUpQuestionResponses.map((r) => r.id)

      // if screen conditions change, make sure to also update in DeductionsAndCredits/index
      let nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.review

      if (didOwnHome) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.ownedHome
      } else if (didWorkFromHome) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice
      } else if (didHaveBusinessMileage) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.businessMileage
      } else if (didOwnFarm) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.farm
      } else if (didHaveCollegeStudents) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.education
      } else if (didPayDependentCareExpenses) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.dependentCare
      } else if (didHaveCharitableContribution) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.charitableContributions
      }

      const idsToDelete: number[] = []
      if (!didOwnHome && paidMortgageInterestQR.initialResponse !== false) {
        responseData.push({
          id: paidMortgageInterestQR.responseId,
          value: false,
          annualTaxFilingFormId: formId,
          questionId: TaxListQuestionId.paid_mortgage_interest,
        })
      }
      if (!didWorkFromHome && workedFromHomeFollowupIds.length) {
        idsToDelete.push(...workedFromHomeFollowupIds)
      }
      if (!didHaveBusinessMileage && hadBusinessMileageFollowupIds.length) {
        idsToDelete.push(...hadBusinessMileageFollowupIds)
      }
      if (!didOwnFarm && numberOfFarmsQR.initialResponse !== 0) {
        responseData.push({
          id: numberOfFarmsQR.responseId,
          value: 0,
          annualTaxFilingFormId: formId,
          questionId: TaxListQuestionId.number_of_farms,
        })
      }
      if (!didHaveCollegeStudents && hadCollegeStudentsFollowupIds.length) {
        idsToDelete.push(...hadCollegeStudentsFollowupIds)
      }
      if (
        !didPayDependentCareExpenses &&
        didPayDependentCareExpensesFollowupIds.length
      ) {
        idsToDelete.push(...didPayDependentCareExpensesFollowupIds)
      }
      if (
        !didHaveCharitableContribution &&
        charitableContributionsFollowupIds.length
      ) {
        idsToDelete.push(...charitableContributionsFollowupIds)
      }

      let deletionSucceeded = true
      if (idsToDelete.length > 0) {
        deletionSucceeded = Boolean(
          await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
        )
      }

      //process progresses for the step and substeps
      let progressUpdateSucceeded = false
      if (deletionSucceeded) {
        //there should always be a completed progress for the first tile page upon submission
        const completedSteps = [SubStepIdentifiers.deductionsAndCredits]
        const incompleteSteps: SubStepIdentifiers[] = []

        if (didOwnHome) {
          if (!ownedHomeFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.ownedHome)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.ownedHome)
        }

        if (didWorkFromHome) {
          if (!workedFromHomeFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.homeOffice)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.homeOffice)
        }

        if (didHaveBusinessMileage) {
          if (!hadBusinessMileageFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.businessMileage)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.businessMileage)
        }

        if (didOwnFarm) {
          if (
            ownedFarmQR.initialResponse !== didOwnFarm ||
            !ownedFarmQR.initialResponse
          ) {
            incompleteSteps.push(SubStepIdentifiers.farm)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.farm)
        }

        if (didHaveCollegeStudents) {
          if (!hadCollegeStudentsFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.education)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.education)
        }

        if (didPayDependentCareExpenses) {
          if (!didPayDependentCareExpensesFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.dependentCare)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.dependentCare)
        }

        if (didHaveCharitableContribution) {
          if (!charitableContributionsFollowupIds.length) {
            incompleteSteps.push(SubStepIdentifiers.charitableContributions)
          }
        } else {
          completedSteps.push(SubStepIdentifiers.charitableContributions)
        }

        progressUpdateSucceeded = await updateProgressData({
          completedSteps,
          incompleteSteps,
        })
      }

      if (deletionSucceeded && progressUpdateSucceeded) {
        await goToNextStep(
          [...tilesToResponses(), ...responseData],
          nextScreen ?? null
        )
      }
    } catch (err) {
      logSentryError(err)
    }
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (form?.year) {
      dispatch(fetchUserEoyReviewProgress(form?.year))
    }
  }, [dispatch, form?.year])

  return (
    <Grid>
      <GridRowColumn centerContent {...tqBigSpace}>
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass.svg" />
      </GridRowColumn>
      <GridRowColumn {...tqBigSpace}>
        <Text as="display2" textAlign="center">
          Deductions and Credits
        </Text>
      </GridRowColumn>
      {errorAlert}
      <GridRowColumn {...tqSmallSpace}>
        <Text as="bodyLg" textAlign="center">
          Let us know if any of these applied to you in {taxYear}, so we can
          personalize this questionnaire for you.
        </Text>
      </GridRowColumn>
      {tileSelections}
      {noneSelection}
      <Grid.Row />
      <FormFlowFooter
        isSubmit={noneApplySelected}
        continueDisabled={saveAndContinue.loading}
        onBack={() => goBack(previousScreen ?? null)}
        onForward={
          !noneApplySelected && !anyResponseSelected
            ? triggerError
            : saveAndContinue.callback
        }
        loading={isUpdating || saveAndContinue.loading}
      />
    </Grid>
  )
}

export default DeductionsAndCreditsPanel
