import axios from 'axios'
import { fetchWrapper } from '../../../reducers/fetch'
import {
  DepreciableAssetTransaction,
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  EndOfYearReviewSubmission,
  EndOfYearReviewSubmissionStep,
  StepContext,
} from './types'

interface BookkeepingStep {
  id: number
  step: string
  status: string
  context: StepContext
}

/**
 * Fetches the YE Bookkeeping step, including the step context.
 * Step context is used to store the state of the step and is
 * currently untyped. This may change in future updates.
 *
 * @param stepId the backend identifier for the step
 * @returns step
 */
export const userGetBookkeepingStep = (stepId: string) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching step context.',
    fetchFunction: (_) =>
      axios
        .get<BookkeepingStep>(`/finances/api/v1/bookkeeping/year-end/${stepId}`)
        .then((json) => json.data),
  })

/**
 * Updates the YE Bookkeeping step context for the fromStep and
 * updates the last working on in YE Home to the toStep.
 *
 * @param fromStepId the backend identifier for the step origin
 * @param toStepId the backend identifier for the step target
 * @param context the fromStep context to be updated
 * @returns the updated step context or an empty object on error
 */
export const userUpdateBookkeepingStepContext = (
  fromStepId: string,
  toStepId: string,
  context: StepContext
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating step context.',
    defaultValue: null,
    fetchFunction: (_) =>
      axios
        .post<BookkeepingStep>(
          `/finances/api/v1/bookkeeping/year-end/${fromStepId}`,
          {
            toStep: toStepId,
            context: context ? context : undefined,
          }
        )
        .then((json) => json.data),
  })

/**
 * Updates the status of the appropriate YE Bookkeeping module
 * to complete. The backend will determine which module to mark
 * as complete based on the state of the user's bookkeeping.
 *
 * @returns true if the books were submitted successfully
 */
export const userSubmitBookkeepingModule = () =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error submitting your books.',
    defaultValue: false,
    fetchFunction: (_) =>
      axios.post('/finances/api/v1/bookkeeping/year-end').then(() => true),
  })

/**
 * Checks user's transactions to determine if they have
 * processing fees.
 * @returns a collection of merchant transaction roll-ups
 * [{
 * ..merchantName: string
 * ..transactionAmount: number
 * ..transactionCount: number
 * }]
 */
export const userGetProcessingFees = (
  year: string,
  transactionCategoryIdentifiers?: string[],
  merchants?: string[]
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching your transactions.',
    fetchFunction: (_) =>
      axios
        .post('/finances/api/v1/transactions/summary/by_merchant', {
          year,
          transactionCategoryIdentifiers,
          merchants,
        })
        .then((json) => json.data),
  })

export const FETCH_ADMIN_GET_SUBMISSIONS_KEY = 'FETCH_ADMIN_GET_SUBMISSIONS_KEY'
/**
 * Used by admins to fetch all submissions for a user in year desc order.
 *
 * @param userId id of the user
 * @returns array of EndOfYearReviewSubmissions
 */
export const adminGetSubmissions = (userId: number) => {
  return fetchWrapper({
    fetchKey: FETCH_ADMIN_GET_SUBMISSIONS_KEY,
    defaultErrorMessage: 'There was an error fetching submissions.',
    defaultValue: [],
    fetchFunction: (_) =>
      axios
        .get<
          EndOfYearReviewSubmission[]
        >(`/finances/api/v1/admin/bookkeeping/year-end/${userId}`)
        .then((json) => json.data),
  })
}

export const FETCH_ADMIN_GET_SUBMISSION_KEY = 'FETCH_ADMIN_GET_SUBMISSION_KEY'
/**
 * Used by admins to fetch a submission for a user/year.
 *
 * @param userId id of the user
 * @param year year of the submission
 * @returns the EndOfYearReviewSubmission or null on error
 */
export const adminGetSubmission = (userId: number, year: string) => {
  return fetchWrapper({
    fetchKey: FETCH_ADMIN_GET_SUBMISSION_KEY,
    defaultErrorMessage: 'There was an error fetching this submission.',
    defaultValue: null,
    fetchFunction: (_) =>
      axios
        .get<EndOfYearReviewSubmission>(
          `/finances/api/v1/admin/bookkeeping/year-end/${userId}/${year}`
        )
        .then((json) => json.data),
  })
}

export const FETCH_ADMIN_UPDATE_STEP_KEY = 'FETCH_ADMIN_UPDATE_STEP_KEY'
/**
 * Used by admins when updating a user's submission.
 *
 * @param userId id of the user
 * @param step admin name of the step being updated
 * @param context optional context update
 * @param status optional status update
 * @returns updated EndOfYearReviewSubmissionStep
 */
export const adminUpdateBookkeepingStep = ({
  userId,
  step,
  context,
  status,
}: {
  userId: number
  step: EndOfYearAdminReviewStep
  context?: StepContext
  status?: EndOfYearReviewStepStatus
}) => {
  return fetchWrapper({
    fetchKey: FETCH_ADMIN_UPDATE_STEP_KEY,
    defaultErrorMessage: 'There was an error updating step data.',
    defaultValue: null,
    fetchFunction: (_) =>
      axios
        .post<EndOfYearReviewSubmissionStep>(
          `/finances/api/v1/admin/bookkeeping/year-end/${step}`,
          {
            userId,
            context,
            status,
          }
        )
        .then((json) => json.data),
  })
}

export const FETCH_ADMIN_LIST_DEPRECIABLE_ASSETS_KEY = (userId: number) =>
  `FETCH_ADMIN_LIST_DEPRECIABLE_ASSETS_KEY_${userId}`
/**
 * Used by admins to fetch the list of transactions associated
 * with depreciable assets in a given tax year
 *
 * @param userId id of the user
 * @param year tax year
 * @returns array of DepreciableAssetTransaction or null on error
 */
export const adminListDepreciableAssets = (userId: number, year: string) => {
  return fetchWrapper({
    fetchKey: FETCH_ADMIN_LIST_DEPRECIABLE_ASSETS_KEY(userId),
    defaultErrorMessage:
      'There was an error fetching depreciable asset transactions.',
    defaultValue: null,
    fetchFunction: (_) =>
      axios
        .get<
          DepreciableAssetTransaction[]
        >(`/finances/api/v1/admin/transactions/end_of_year_review/${year}/assets_over_2500/${userId}`)
        .then((json) => json.data),
  })
}

export const FETCH_ADMIN_GET_PRIOR_YEAR_RE_KEY = (userId: number) =>
  `FETCH_ADMIN_GET_PRIOR_YEAR_RE_KEY_${userId}`
/**
 * Used by admins when viewing a user's retained earnings from last tax year.
 *
 * @param userId id of the user
 * @param year current tax year
 * @returns amount in cents or null
 */
export const adminGetPriorYearRetainedEarnings = (
  userId: number,
  taxYear: string | number
) => {
  return fetchWrapper({
    fetchKey: FETCH_ADMIN_GET_PRIOR_YEAR_RE_KEY(userId),
    defaultErrorMessage:
      'There was an error fetching previous year retained earnings.',
    defaultValue: null,
    fetchFunction: (_) =>
      axios
        .get<
          number | null
        >(`/finances/api/v1/admin/${userId}/balance_sheets/${taxYear}/prior_year_retained_earnings`)
        .then((json) => json.data),
  })
}

export type AdminUpdatePriorYearRetainedEarningsProps = {
  userId: number
  taxYear: string | number
  amountInCents: number
}
export const FETCH_ADMIN_UPDATE_PRIOR_YEAR_RE_KEY = (userId: number) =>
  `FETCH_ADMIN_UPDATE_PRIOR_YEAR_RE_KEY_${userId}`
/**
 * Used by admins when updating a user's retained earnings from last tax year.
 *
 * @param userId id of the user
 * @param year current tax year
 * @param amountInCents prior year retained earnings
 * @returns undefined
 */
export const adminUpdatePriorYearRetainedEarnings = ({
  userId,
  taxYear,
  amountInCents,
}: AdminUpdatePriorYearRetainedEarningsProps) => {
  return fetchWrapper({
    fetchKey: FETCH_ADMIN_UPDATE_PRIOR_YEAR_RE_KEY(userId),
    defaultErrorMessage:
      'There was an error updating previous year retained earnings.',
    defaultValue: null,
    fetchFunction: (_) =>
      axios
        .post<undefined>(
          `/finances/api/v1/admin/${userId}/balance_sheets/${taxYear}/prior_year_retained_earnings`,
          { amountInCents }
        )
        .then(() => undefined),
  })
}

export const FETCH_ADMIN_SUBMIT_YE_BOOKKEEPING_KEY = (userId: number) =>
  `FETCH_ADMIN_SUBMIT_YE_BOOKKEEPING_KEY_${userId}`
/**
 * Updates the status of the YE followup bookkeeping module. The backend
 * will determine which module to mark the correct status to set based on
 * the status of each individual step.
 *
 * @returns true if the books were submitted successfully
 */
export const adminSubmitBookkeepingModule = (userId: number) =>
  fetchWrapper({
    fetchKey: FETCH_ADMIN_UPDATE_PRIOR_YEAR_RE_KEY(userId),
    defaultErrorMessage:
      'There was an error submitting year-end bookkeeping for this user.',
    defaultValue: null,
    fetchFunction: (_) =>
      axios
        .post<EndOfYearReviewSubmission>(
          `/finances/api/v1/admin/bookkeeping/year-end/${userId}/submission`
        )
        .then((json) => json.data),
  })
