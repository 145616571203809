import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import { TaxListQuestionId, useTaxListQuestionRes } from '../service'
import {
  FormikInput,
  getFieldNames,
  GridRowColumn,
  makeNumberSchema,
  Text,
} from '../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import { Form1120sProps } from '.'
import { SubStepIdentifiers } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../Shared/SkipQuestion'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TQYesNoResponse } from '../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../helpers'

export const uncommonScenarioQuestionIds = [
  TaxListQuestionId.scorp_owned_other_businesses,
  TaxListQuestionId.scorp_number_other_businesses,
  TaxListQuestionId.sold_fixed_assets,
  TaxListQuestionId.number_fixed_assets_sold,
  TaxListQuestionId.invested_in_rnd,
  TaxListQuestionId.engaged_in_real_estate,
  TaxListQuestionId.number_of_real_estate,
]

const UncommonScenariosPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1120sProps) => {
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const ownedOtherBizQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.scorp_owned_other_businesses,
  })

  const numOfOtherBizQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.scorp_number_other_businesses,
  })

  const soldFixedAssetsQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.sold_fixed_assets,
  })

  const numFixedAssetsQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.number_fixed_assets_sold,
  })

  const rndQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.invested_in_rnd,
  })

  const realEstateQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.engaged_in_real_estate,
  })

  const numRealEstateQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.number_of_real_estate,
  })

  const skippedQuestions = useSkipQuestion(uncommonScenarioQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.scorp_owned_other_businesses]:
        ownedOtherBizQR.initialResponse,
      [TaxListQuestionId.invested_in_rnd]: rndQR.initialResponse,
      [TaxListQuestionId.sold_fixed_assets]: soldFixedAssetsQR.initialResponse,
      [TaxListQuestionId.engaged_in_real_estate]: realEstateQR.initialResponse,
      [TaxListQuestionId.scorp_number_other_businesses]:
        numOfOtherBizQR.initialResponse,
      [TaxListQuestionId.number_fixed_assets_sold]:
        numFixedAssetsQR.initialResponse,
      [TaxListQuestionId.number_of_real_estate]:
        numRealEstateQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values[TaxListQuestionId.scorp_owned_other_businesses] !== true) {
        values[TaxListQuestionId.scorp_number_other_businesses] = 0
      }
      if (values[TaxListQuestionId.sold_fixed_assets] !== true) {
        values[TaxListQuestionId.number_fixed_assets_sold] = 0
      }
      if (values[TaxListQuestionId.engaged_in_real_estate] !== true) {
        values[TaxListQuestionId.number_of_real_estate] = 0
      }
      const responseData = [
        ownedOtherBizQR.getResponseData(values),
        rndQR.getResponseData(values),
        soldFixedAssetsQR.getResponseData(values),
        realEstateQR.getResponseData(values),
        numOfOtherBizQR.getResponseData(values),
        numFixedAssetsQR.getResponseData(values),
        numRealEstateQR.getResponseData(values),
      ]

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.lessCommonScenarios1120s],
      })
    },
  })

  const { isSubmitting, submitForm, values } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid style={{ marginTop: 56 }}>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Less Common Scenarios
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            These are scenarios that are not common for most therapists, but
            they do allow us to get a fuller picture of your tax situation.
            <br />
            <br />
            Please select all that apply for your <b>business</b> in {taxYear}.
          </Text>
        </GridRowColumn>
        <TQYesNoResponse questionResponse={ownedOtherBizQR} skippable />

        {values[TaxListQuestionId.scorp_owned_other_businesses] && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikInput
                name={
                  fieldNames[TaxListQuestionId.scorp_number_other_businesses]
                }
                label={numOfOtherBizQR.label}
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.scorp_number_other_businesses,
                  makeNumberSchema({})
                )}
                type="number"
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn short {...tqSmallSpace}>
              <SkipQuestion
                questionId={TaxListQuestionId.scorp_number_other_businesses}
              />
            </GridRowColumn>
          </>
        )}

        <TQYesNoResponse
          questionResponse={soldFixedAssetsQR}
          description="E.g. desks, laptops"
          skippable
        />

        {values[TaxListQuestionId.sold_fixed_assets] && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikInput
                name={fieldNames[TaxListQuestionId.number_fixed_assets_sold]}
                label={numFixedAssetsQR.label}
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.number_fixed_assets_sold,
                  makeNumberSchema({})
                )}
                type="number"
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn short {...tqSmallSpace}>
              <SkipQuestion
                questionId={TaxListQuestionId.number_fixed_assets_sold}
              />
            </GridRowColumn>
          </>
        )}

        <TQYesNoResponse questionResponse={rndQR} skippable />
        <TQYesNoResponse questionResponse={realEstateQR} skippable />

        {values[TaxListQuestionId.engaged_in_real_estate] && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikInput
                name={fieldNames[TaxListQuestionId.number_of_real_estate]}
                label={numRealEstateQR.label}
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.number_of_real_estate,
                  makeNumberSchema({})
                )}
                type="number"
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn short {...tqSmallSpace}>
              <SkipQuestion
                questionId={TaxListQuestionId.number_of_real_estate}
              />
            </GridRowColumn>
          </>
        )}
        <FormFlowFooter
          continueDisabled={isSubmitting}
          loading={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default UncommonScenariosPanel
