import { useCallback, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import {
  GridRowColumn,
  Alert,
  Loader,
} from '../../../../../components/BaseComponents'
import {
  useSetScreen,
  useFormFlow,
} from '../../../../../components/FormFlow/formFlow'
import {
  invalidateFetch,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../../reducers/fetch'
import {
  useLoadedFlagValue,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../annualTaxFilingForms.slice'
import {
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  postUpdateTaxQuestionnaireResponses,
  fetchUserTaxQuestionnaire,
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
} from '../taxChecklistQuestion.actions'
import { TaxChecklistResponse } from '../taxChecklistQuestion.slice'
import CheckYourDetailsPanel from '../Shared/PersonalDetails/CheckYourDetailsPanel'
import { UPDATE_USER_KEY } from '../../../../../actions/userActions'
import UpdateDetailsPanel from './UpdateDetailsPanel'
import UncommonScenariosPanel from './UncommonScenariosPanel'
import PageHeader from '../../../../../components/shared/PageHeader'
import Form1120SReviewPanel from './ReviewPanel'
import { filterNulls, useAppDispatch } from '../../../../../utils/typeHelpers'
import useProgressSteps, {
  StepUpdates,
} from '../Shared/ReviewStepsandProgresses/useProgressSteps'
import { CHECK_YOUR_DETAILS_1120S } from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { selectTqFormIsReadOnly } from '../../annualTaxFilings.selector'
import { FEATURE_FLAG_KEYS } from '../../../../OpenFeature'
import { fetchUserDocuments } from '../../../../UserDocuments/userDocuments.slice'
import { fetchTaxUserDocumentsIfNeeded } from '../../taxUserDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'

export enum FORM_1120_S_SCREENS {
  confirmDetails = 'confirm-details',
  updateDetails = 'update-details',
  uncommonScenarios = 'less-common-scenarios',
  review = 'review',
}

export interface Form1120sProps {
  goToNextStep: (
    data: Partial<TaxChecklistResponse | undefined>[] | null,
    newScreen: FORM_1120_S_SCREENS | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => FORM_1120_S_SCREENS
  ) => Promise<boolean>
  goBack: (newScreen: FORM_1120_S_SCREENS | null) => void
  nextScreen?: FORM_1120_S_SCREENS | null
  previousScreen?: FORM_1120_S_SCREENS | null
}

const TaxQuestionnaire1120S = () => {
  const dispatch = useAppDispatch()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqFormIsReadOnly, formId)
  const backLink = '/taxes/annual/tax_checklist/'
  const { setScreen, currentScreen } = useSetScreen({
    backLink,
    reviewScreen: FORM_1120_S_SCREENS.review,
  })
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: CHECK_YOUR_DETAILS_1120S }
  )

  const allFetchKeys = useMemo(
    () => [
      ...progressFetchKeys,
      FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
      FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
      FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
      UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
      DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
      UPDATE_USER_KEY,
    ],
    [progressFetchKeys, taxYear]
  )

  const updateError = useReselector(selectErrorsForKeys, allFetchKeys)
  const isFetching = useReselector(selectIsFetchingForKeys, allFetchKeys)

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse | undefined>[] | null,
      newScreen: FORM_1120_S_SCREENS | null,
      stepUpdates: StepUpdates = {}
    ) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))

      const parsedData = data && filterNulls(data)

      if (parsedData?.length) {
        const res = await dispatch(
          postUpdateTaxQuestionnaireResponses(parsedData)
        )
        if (!res) {
          return false
        }
      }

      const progressUpdatesSuccessful = await updateProgressData(stepUpdates)
      if (!progressUpdatesSuccessful) {
        return false
      }
      setScreen(newScreen)
      return true
    },
    [dispatch, setScreen, allFetchKeys, updateProgressData]
  )

  const screenConfig = useMemo(() => {
    const props = { goToNextStep: saveFormData, goBack: setScreen, formId }
    return [
      {
        component: () => <CheckYourDetailsPanel {...props} />,
        screenName: FORM_1120_S_SCREENS.confirmDetails,
        step: 0,
      },
      {
        component: () => <UpdateDetailsPanel {...props} />,
        screenName: FORM_1120_S_SCREENS.updateDetails,
        step: 1,
      },
      {
        component: () => <UncommonScenariosPanel {...props} />,
        screenName: FORM_1120_S_SCREENS.uncommonScenarios,
        step: 2,
      },
      {
        component: () => <Form1120SReviewPanel {...props} />,
        screenName: FORM_1120_S_SCREENS.review,
        step: 3,
      },
    ]
  }, [formId, saveFormData, setScreen])

  const { progressBar, content } = useFormFlow({
    steps: [
      'Confirm Details',
      'Update Details',
      'Less Common Scenarios',
      'Review',
    ],
    screens: screenConfig,
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUserTaxQuestionnaire(taxYear))
  }, [taxYear, dispatch])

  useEffect(() => {
    if (!currentScreen) {
      setScreen(FORM_1120_S_SCREENS.confirmDetails, true)
    }
  }, [setScreen, currentScreen])

  const navigate = useNavigate()
  const enable2024TaxChecklist = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.enable2024TaxChecklist,
    false
  )
  useEffect(() => {
    if (enable2024TaxChecklist === false) {
      navigate('/taxes/annual')
    }
  }, [enable2024TaxChecklist, navigate])

  return (
    <>
      <PageHeader
        backControl={
          readOnly
            ? { link: backLink, text: 'Back to Business Tax Checklist' }
            : undefined
        }
        header={`${taxYear} Business Tax Questionnaire: Check Your Details`}
      />
      <Grid>
        <Loader loading={isFetching} />
        {!readOnly && <GridRowColumn>{progressBar}</GridRowColumn>}
        {updateError.length > 0 && (
          <GridRowColumn>
            <Alert type="error">{updateError[0].message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default TaxQuestionnaire1120S
