import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import { DeductionsAndCreditsStepProps } from '.'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import {
  FormikInput,
  getFieldNames,
  GridRowColumn,
  makeNumberSchema,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

export const charitableContributionsQuestionIds = [
  TaxListQuestionId.charitable_cash_contribution_amount_in_cents,
  TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents,
]

const CharitableContributionsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const cashContributionsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.charitable_cash_contribution_amount_in_cents,
    formId,
    isCurrency: true,
  })

  const nonCashContributions = useTaxListQuestionRes({
    questionId:
      TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents,
    formId,
    isCurrency: true,
  })

  const skippedQuestions = useSkipQuestion(charitableContributionsQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.charitable_cash_contribution_amount_in_cents]:
        cashContributionsQR.initialResponse,
      [TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents]:
        nonCashContributions.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const responses = [
        cashContributionsQR.getResponseData(values),
        nonCashContributions.getResponseData(values),
      ]

      return goToNextStep(responses, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.charitableContributions],
      })
    },
  })

  const { submitForm, isSubmitting, values } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Charitable Contributions
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg" textAlign="center">
            Only enter amounts that have not already been recorded in Heard.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikInput
            name={
              fieldNames[
                TaxListQuestionId.charitable_cash_contribution_amount_in_cents
              ]
            }
            label={cashContributionsQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.charitable_cash_contribution_amount_in_cents,
              makeNumberSchema({
                field: 'cash contributions',
                allowedDecimals: 2,
              })
            )}
            fullWidth
            componentType="currency"
            placeholder="$0"
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace} short>
          <SkipQuestion
            questionId={
              TaxListQuestionId.charitable_cash_contribution_amount_in_cents
            }
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikInput
            name={
              fieldNames[
                TaxListQuestionId
                  .charitable_non_cash_contribution_amount_in_cents
              ]
            }
            label={nonCashContributions.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents,
              makeNumberSchema({
                field: 'non-cash contributions',
                allowedDecimals: 2,
              })
            )}
            fullWidth
            componentType="currency"
            placeholder="$0"
            afterLabel={
              <Popup
                content={
                  <>
                    <Text as="h3">What is a non-cash contribution?</Text>
                    <br />
                    <Text as="bodySm">
                      Non-cash contributions can include physical goods such as
                      artwork, clothing, vehicles, etc, and also real estate
                      property. It doesn&apos;t include cash, check, or other
                      monetary gifts.
                    </Text>
                  </>
                }
              />
            }
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace} short>
          <SkipQuestion
            questionId={
              TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents
            }
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <UploadDocumentSubSection
          categories={[
            values[
              TaxListQuestionId.charitable_cash_contribution_amount_in_cents
            ] ||
            values[
              TaxListQuestionId.charitable_non_cash_contribution_amount_in_cents
            ]
              ? UserDocumentCategoryIdentifier.charity
              : undefined,
          ]}
        />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default CharitableContributionsPanel
