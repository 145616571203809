import { useState, useCallback, useMemo } from 'react'
import { Alert, GridRowColumn } from '../../../../../components/BaseComponents'

const useMissingAnswerValidation = (missingAnswerError: string) => {
  const [error, setError] = useState('')

  const clearError = useCallback(() => {
    setError('')
  }, [])

  const triggerError = useCallback(() => {
    setError(missingAnswerError)
  }, [missingAnswerError])

  const errorAlert = useMemo(
    () =>
      error ? (
        <GridRowColumn>
          <Alert type="error">{error}</Alert>
        </GridRowColumn>
      ) : null,
    [error]
  )

  return {
    errorAlert,
    clearError,
    triggerError,
  }
}

export default useMissingAnswerValidation
