import { useFormik, FormikProvider } from 'formik'
import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { Form1040DetailsProps } from '.'
import {
  GridRowColumn,
  FormikInput,
  makeNumberSchema,
  Text,
  getFieldNames,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import { selectPayerHasAboundTaxDocsForYear } from '../../../aboundAnnualTaxFilings.selector'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { TQYesNoResponse } from '../../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const businessInfoQuestionIds = [
  TaxListQuestionId.paid_contractors,
  TaxListQuestionId.sprop_owned_other_businesses,
  TaxListQuestionId.sprop_number_other_businesses,
]

const BusinessInfoPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: Form1040DetailsProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)

  const didPayContractors = useReselector(
    selectPayerHasAboundTaxDocsForYear,
    taxYear
  )
  const paidContractorsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.paid_contractors,
    formId,
  })
  const ownedOtherBizQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.sprop_owned_other_businesses,
    formId,
  })
  const numberOtherBizQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.sprop_number_other_businesses,
    formId,
  })
  const skippedQuestions = useSkipQuestion(businessInfoQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.paid_contractors]:
        paidContractorsQR.initialResponse ??
        (didPayContractors ? true : undefined),
      [TaxListQuestionId.sprop_owned_other_businesses]:
        ownedOtherBizQR.initialResponse,
      [TaxListQuestionId.sprop_number_other_businesses]:
        numberOtherBizQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values[TaxListQuestionId.sprop_owned_other_businesses] !== true) {
        values[TaxListQuestionId.sprop_number_other_businesses] = 0
      }

      const responses = [
        paidContractorsQR.getResponseData(values),
        ownedOtherBizQR.getResponseData(values),
        numberOtherBizQR.getResponseData(values),
      ]

      await goToNextStep(responses, nextScreen ?? null)
    },
  })

  const { values, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Update Business Information
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            Please update the following information for {taxYear}. These answers
            will appear on your tax return.
          </Text>
        </GridRowColumn>

        <TQYesNoResponse questionResponse={paidContractorsQR} skippable />
        <TQYesNoResponse questionResponse={ownedOtherBizQR} skippable />

        {values[TaxListQuestionId.sprop_owned_other_businesses] && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikInput
                name={
                  fieldNames[TaxListQuestionId.sprop_number_other_businesses]
                }
                label={numberOtherBizQR.label}
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.sprop_number_other_businesses,
                  makeNumberSchema({})
                )}
                type="number"
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn short {...tqSmallSpace}>
              <SkipQuestion
                questionId={TaxListQuestionId.sprop_number_other_businesses}
              />
            </GridRowColumn>
          </>
        )}
        <FormFlowFooter
          loading={isSubmitting}
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default BusinessInfoPanel
