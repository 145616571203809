import { useEffect } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { LIFE_CHANGES_SCREENS, LifeChangesStepProps } from '.'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import { selectTaxListQuestionResponsesByQuestionIds } from '../../taxChecklist.selectors'
import { useTiles } from '../../Shared/UseTiles'
import { soldHomeQuestionIds } from './SoldHomePanel'
import { purchasedHomeQuestionIds } from './PurchasedHomePanel'
import { deleteTaxQuestionnaireResponses } from '../../taxChecklistQuestion.actions'
import useProgressSteps from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import {
  CHANGES_IN_YEAR,
  SubStepIdentifiers,
} from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const lifeChangesQuestionIds = [
  TaxListQuestionId.sold_home,
  TaxListQuestionId.home_purchase,
]

const LifeChangePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: LifeChangesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form = useReselector(select1040FormForYear, taxYear)
  const formId = form?.id
  const dispatch = useAppDispatch()
  const { updateProgressData } = useProgressSteps({ steps: CHANGES_IN_YEAR })

  const soldHomeQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.sold_home,
  })

  const purchasedHomeQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.home_purchase,
  })

  const soldHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    soldHomeQuestionIds,
    formId
  )
  const purchasedHomeFollowUpQuestionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    purchasedHomeQuestionIds,
    formId
  )

  const {
    tiles: selections,
    setTiles,
    tilesToResponses,
    anyResponseSelected,
    noneApplySelected,
    tileSelections,
    noneSelection,
  } = useTiles({})

  useEffect(() => {
    if (selections.length || !soldHomeQR.loaded || !purchasedHomeQR.loaded) {
      return
    }

    setTiles([
      {
        icon: light('sign-hanging'),
        text: soldHomeQR.label,
        questionId: TaxListQuestionId.sold_home,
        response: soldHomeQR.firstResponse,
      },
      {
        icon: light('key'),
        text: purchasedHomeQR.label,
        questionId: TaxListQuestionId.home_purchase,
        response: purchasedHomeQR.firstResponse,
      },
    ])
  }, [
    purchasedHomeQR.firstResponse,
    purchasedHomeQR.label,
    purchasedHomeQR.loaded,
    selections.length,
    setTiles,
    soldHomeQR.firstResponse,
    soldHomeQR.label,
    soldHomeQR.loaded,
  ])

  const saveAndContinue = useAsyncCallback(async () => {
    // if screen conditions change, make sure to also update in LifeChanges/index
    let nextScreen = LIFE_CHANGES_SCREENS.miscQuestions
    const didSellHome =
      selections.find((s) => s.questionId === TaxListQuestionId.sold_home)
        ?.response?.value === true
    const soldHomeFollowUpIds = soldHomeFollowUpQuestionResponses.map(
      (r) => r.id
    )
    const didPurchaseHome =
      selections.find((s) => s.questionId === TaxListQuestionId.home_purchase)
        ?.response?.value === true
    const purchasedHomeFollowUpIds = purchasedHomeFollowUpQuestionResponses.map(
      (r) => r.id
    )

    if (didSellHome) {
      nextScreen = LIFE_CHANGES_SCREENS.soldHome
    } else if (didPurchaseHome) {
      nextScreen = LIFE_CHANGES_SCREENS.purchasedHome
    }

    let deletionSucceeded = true
    const idsToDelete: number[] = []
    if (!didSellHome && soldHomeFollowUpIds.length) {
      idsToDelete.push(...soldHomeFollowUpIds)
    }
    if (!didPurchaseHome && purchasedHomeFollowUpIds.length) {
      idsToDelete.push(...purchasedHomeFollowUpIds)
    }
    if (idsToDelete.length > 0) {
      deletionSucceeded = Boolean(
        await dispatch(deleteTaxQuestionnaireResponses(idsToDelete))
      )
    }

    //process progresses for the step and substeps
    let progressUpdateSucceeded = false
    if (deletionSucceeded) {
      //there should always be a completed progress for the first tile page upon submission

      const completedSteps = [SubStepIdentifiers.bigLifeChanges]
      const incompleteSteps: SubStepIdentifiers[] = []

      if (didSellHome) {
        if (!soldHomeFollowUpIds.length) {
          incompleteSteps.push(SubStepIdentifiers.soldHome)
        }
      } else {
        completedSteps.push(SubStepIdentifiers.soldHome)
      }

      if (didPurchaseHome) {
        if (!purchasedHomeFollowUpIds.length) {
          incompleteSteps.push(SubStepIdentifiers.purchasedHome)
        }
      } else {
        completedSteps.push(SubStepIdentifiers.purchasedHome)
      }

      progressUpdateSucceeded = await updateProgressData({
        completedSteps,
        incompleteSteps,
      })
    }
    if (deletionSucceeded && progressUpdateSucceeded) {
      await goToNextStep(tilesToResponses(), nextScreen ?? null)
    }
  })

  return (
    <Grid>
      <GridRowColumn centerContent {...tqBigSpace}>
        <Image src="https://heard-images.s3.amazonaws.com/assets/calendar-day-dec-31.svg" />
      </GridRowColumn>
      <GridRowColumn {...tqBigSpace}>
        <Text as="display2" textAlign="center">
          Tell us about your year
        </Text>
      </GridRowColumn>
      <GridRowColumn {...tqSmallSpace}>
        <Text as="bodyLg" textAlign="center">
          Let us know if any of these life events happened in {taxYear}, so we
          can personalize this questionnaire for you.
        </Text>
      </GridRowColumn>
      {tileSelections}
      {noneSelection}
      <Grid.Row />
      <FormFlowFooter
        continueDisabled={
          (!noneApplySelected && !anyResponseSelected) ||
          saveAndContinue.loading
        }
        onBack={() => goBack(previousScreen ?? null)}
        onForward={saveAndContinue.callback}
        loading={saveAndContinue.loading}
      />
    </Grid>
  )
}

export default LifeChangePanel
