import { useParams } from 'react-router-dom'
import {
  Alert,
  Checkbox,
  GridRowColumn,
} from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import { Grid, List } from 'semantic-ui-react'
import { useState } from 'react'
import './styles.scss'

export const FinalSignOff = ({
  notesAdded,
  completedTQ,
  optedOutOfTaxes,
}: {
  notesAdded: boolean
  completedTQ: boolean
  optedOutOfTaxes: boolean
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const { year } = useParams()

  const onAcknowledgeClick = () => {
    setIsChecked(!isChecked)
  }

  const getBulletList = () => {
    if (completedTQ) {
      return (
        <List bulleted className="flex-column bulleted-list">
          <List.Item>
            You&apos;ve reviewed your income and expenses and have identified
            any transactions that require correction.
          </List.Item>
          <List.Item>
            Your finalized books will be automatically sent to your tax preparer{' '}
            along with your completed Tax Questionaire.
          </List.Item>
        </List>
      )
    } else if (optedOutOfTaxes) {
      return (
        <List bulleted className="flex-column bulleted-list">
          <List.Item>
            You&apos;ve reviewed your income and expenses and have identified
            any transactions that require correction.
          </List.Item>
        </List>
      )
    } else {
      return (
        <List bulleted className="flex-column bulleted-list">
          <List.Item>
            You&apos;ve reviewed your income and expenses and have identified
            any transactions that require correction.
          </List.Item>
          <List.Item>
            Your finalized books will be automatically sent to your tax preparer{' '}
            after you complete my Tax Questionnaire.
          </List.Item>
        </List>
      )
    }
  }

  return (
    <Grid>
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          customColor="green"
          description={
            notesAdded
              ? 'Our bookkeeping team will review your notes and make any corrections. You`ll then have another opportunity to review your books before signing off.'
              : `If everything looks satisfactory, finalize your ${year} books by signing off below.`
          }
          imageSrc={
            notesAdded
              ? 'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.svg'
              : 'https://heard-images.s3.amazonaws.com/assets/bookkeeping.svg'
          }
          imageAlt="bookkeeping"
          imageWidth={180}
          imageHeight={180}
          title={
            notesAdded ? "We'll take another look!" : 'Sign off on your books'
          }
        >
          {!notesAdded && (
            <GridRowColumn>
              <div className="alert-footer">
                <Alert
                  title="You acknowledge that:"
                  style={{ width: '520px' }}
                  customIcon="none"
                  contentStyle={{ textAlign: 'left' }}
                  type="acknowledge"
                >
                  {getBulletList()}
                  <Checkbox
                    id={'acknowledge'}
                    label="I acknowledge the above and sign off"
                    checked={isChecked}
                    onClick={onAcknowledgeClick}
                  />
                </Alert>
              </div>
            </GridRowColumn>
          )}
        </StepHeaders>
      </GridRowColumn>
    </Grid>
  )
}
