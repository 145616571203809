import { useCallback, useEffect, useMemo } from 'react'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../../../components/FormFlow/formFlow'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  fetchUserTaxQuestionnaire,
  postUpdateTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  useLoadedFlagValue,
  useReselector,
} from '../../../../../../utils/sharedHooks'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  invalidateFetch,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../../../reducers/fetch'
import {
  Alert,
  GridRowColumn,
  Loader,
} from '../../../../../../components/BaseComponents'
import LifeChangePanel from './LifeChangesPanel'
import { Grid } from 'semantic-ui-react'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../../annualTaxFilingForms.slice'
import SoldHomePanel from './SoldHomePanel'
import PurchasedHomePanel from './PurchasedHomePanel'
import MiscQuestionsPanel from './MiscQuestionsPanel'
import LifeChangesReviewPanel from './ReviewPanel'
import { selectTaxListQuestion } from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import PageHeader from '../../../../../../components/shared/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import useProgressSteps, {
  StepUpdates,
} from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { CHANGES_IN_YEAR } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  filterNulls,
  useAppDispatch,
} from '../../../../../../utils/typeHelpers'
import { selectTqFormIsReadOnly } from '../../../annualTaxFilings.selector'
import { FEATURE_FLAG_KEYS } from '../../../../../OpenFeature'
import { fetchUserDocuments } from '../../../../../UserDocuments/userDocuments.slice'
import { fetchTaxUserDocumentsIfNeeded } from '../../../taxUserDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'

export enum LIFE_CHANGES_SCREENS {
  lifeChanges = 'life-events',
  soldHome = 'sold-home',
  purchasedHome = 'purchased-home',
  miscQuestions = 'miscellaneous-questions',
  review = 'review',
}

export interface LifeChangesStepProps {
  goToNextStep: (
    data: Partial<TaxChecklistResponse | undefined>[] | null,
    newScreen: LIFE_CHANGES_SCREENS | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => LIFE_CHANGES_SCREENS
  ) => Promise<boolean>
  goBack: (newScreen: LIFE_CHANGES_SCREENS | null) => void
  nextScreen?: LIFE_CHANGES_SCREENS | null
  previousScreen?: LIFE_CHANGES_SCREENS | null
}

const LifeChanges = () => {
  const dispatch = useAppDispatch()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqFormIsReadOnly, formId)
  const backLink = '/taxes/annual/tax_checklist/'
  const { setScreen, currentScreen } = useSetScreen({
    backLink,
    reviewScreen: LIFE_CHANGES_SCREENS.review,
  })
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const { updateProgressData, fetchKeys } = useProgressSteps({
    steps: CHANGES_IN_YEAR,
  })

  const allFetchKeys = useMemo(
    () => [
      FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
      FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
      FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
      UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
      DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
      ...fetchKeys,
    ],
    [taxYear, fetchKeys]
  )

  const updateError = useReselector(selectErrorsForKeys, allFetchKeys)
  const isFetching = useReselector(selectIsFetchingForKeys, allFetchKeys)

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse | undefined>[] | null,
      newScreen: LIFE_CHANGES_SCREENS | null,
      stepUpdates: StepUpdates = {}
    ) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))

      const dataToSave = data && filterNulls(data)

      if (dataToSave?.length) {
        const res = await dispatch(
          postUpdateTaxQuestionnaireResponses(dataToSave)
        )
        if (res) {
          const progressUpdatesSuccessful =
            await updateProgressData(stepUpdates)
          if (!progressUpdatesSuccessful) {
            return false
          }
          setScreen(newScreen)
          return true
        }
      } else {
        const progressUpdatesSuccessful = await updateProgressData(stepUpdates)
        if (!progressUpdatesSuccessful) {
          return false
        }
        setScreen(newScreen)
        return true
      }
      return false
    },
    [dispatch, setScreen, allFetchKeys, updateProgressData]
  )

  const props = useMemo(() => {
    return {
      goToNextStep: saveFormData,
      goBack: setScreen,
    }
  }, [saveFormData, setScreen])

  const questionSoldHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.sold_home,
    taxYear
  )
  const questionPurchasedHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_purchase,
    taxYear
  )

  const applicableScreens = useMemo(() => {
    const newScreens = [
      {
        component: () => <LifeChangePanel {...props} />,
        screenName: LIFE_CHANGES_SCREENS.lifeChanges,
      },
      {
        component: () => <MiscQuestionsPanel {...props} />,
        screenName: LIFE_CHANGES_SCREENS.miscQuestions,
        progressText: 'Miscellaneous Questions',
      },
      {
        component: () => <LifeChangesReviewPanel {...props} />,
        screenName: LIFE_CHANGES_SCREENS.review,
        progressText: 'Review',
      },
    ]
    if (questionSoldHome?.responses?.[0]?.value === true) {
      newScreens.splice(1, 0, {
        component: () => <SoldHomePanel {...props} />,
        screenName: LIFE_CHANGES_SCREENS.soldHome,
        progressText: 'Sold a Home',
      })
    }
    if (questionPurchasedHome?.responses?.[0]?.value === true) {
      const startAt = questionSoldHome?.responses?.[0]?.value === true ? 2 : 1
      newScreens.splice(startAt, 0, {
        component: () => <PurchasedHomePanel {...props} />,
        screenName: LIFE_CHANGES_SCREENS.purchasedHome,
        progressText: 'Purchased a Home',
      })
    }
    return newScreens
  }, [props, questionSoldHome, questionPurchasedHome])

  const screenConfig = useMemo(() => {
    return applicableScreens.map((s, i) => ({
      ...s,
      step: i - 1,
    }))
  }, [applicableScreens])

  const { progressBar, content } = useFormFlow({
    steps: applicableScreens.flatMap((s) =>
      s.progressText ? [s.progressText] : []
    ),
    screens: screenConfig,
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    if (taxYear) {
      dispatch(fetchUserTaxQuestionnaire(taxYear))
    }
  }, [taxYear, dispatch])

  useEffect(() => {
    if (!currentScreen) {
      setScreen(LIFE_CHANGES_SCREENS.lifeChanges, true)
    }
  }, [setScreen, currentScreen])

  const navigate = useNavigate()
  const enable2024TaxChecklist = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.enable2024TaxChecklist,
    false
  )
  useEffect(() => {
    if (enable2024TaxChecklist === false) {
      navigate('/taxes/annual')
    }
  }, [enable2024TaxChecklist, navigate])

  return (
    <>
      <PageHeader
        backControl={
          readOnly
            ? { link: backLink, text: 'Back to Personal Tax Checklist' }
            : undefined
        }
        header={`${taxYear} Tax Questionnaire: Form 1040`}
      />
      <Grid>
        <Loader loading={isFetching} />
        {!readOnly &&
          !isFetching &&
          currentScreen !== LIFE_CHANGES_SCREENS.lifeChanges && (
            <GridRowColumn>{progressBar}</GridRowColumn>
          )}
        {updateError.length > 0 && (
          <GridRowColumn>
            <Alert type="error">{updateError[0].message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default LifeChanges
