import { useEffect, useState } from 'react'
import { Container, Divider } from 'semantic-ui-react'
import { Colors } from '../../../../styles/theme'
import { Link, Loader, Text } from '../../../../components/BaseComponents'
import { UserWithAdminInfo } from '../../../../reducers/admin/allUsersReducer'
import { FinancialAccountWithAdminInfo } from '../../../../reducers/admin/financialAccountsReducer'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import {
  AssetDepreciationDocumentsStep,
  ContractorDocumentsStep,
  DepreciableAssetTransaction,
  DisconnectedAccountsStep,
  EndOfYearAdminReviewStep,
  EndOfYearReviewSubmission,
  EndOfYearSubmissionStatus,
  FinalReviewStep,
  KeyedFetchError,
  MissingBankStatementsStep,
  OtherDocumentsStep,
  OutstandingTransactionsStep,
  PayrollDocumentsStep,
  PreviousYearReturnStep,
  ProcessingFeeDocumentsStep,
} from '../types'
import { TEST_IDS } from './helpers'
import Errors from './errors'
import EndOfYearReviewGroup from './group'
import FinalReviewSection from './final-review'
import DisconnectedAccountsSection from './disconnected-accounts'
import MissingBankStatementsSection from './missing-bank-statements'
import OutstandingTransactionsSection from './outstanding-transactions'
import PayrollDocumentsSection from './documents-payroll'
import ContractorDocumentsSection from './documents-contractor'
import ProcessingFeeDocumentsSection from './documents-processing-fee'
import AssetDepreciationDocumentsSection from './documents-asset-depreciation'
import OtherDocumentsSection from './documents-other'
import PreviousTaxReturnSection from './previous-tax-return'
import SubmitSection from './submit-section'
import SubmissionBanner from './submission-banner'
import './styles.scss'

export type MainContentProps = {
  user: UserWithAdminInfo | undefined
  submission: EndOfYearReviewSubmission | undefined | null
  financialAccounts: FinancialAccountWithAdminInfo[]
  userDocuments: UserDocument[]
  depreciableAssets: DepreciableAssetTransaction[] | null
  errors: KeyedFetchError[]
  loading: boolean
}

const MainContent = ({
  user,
  submission,
  financialAccounts,
  userDocuments,
  depreciableAssets,
  errors,
  loading,
}: MainContentProps) => {
  const [finalReviewStep, setFinalReviewStep] =
    useState<FinalReviewStep | null>(null)
  const [disconnectedAccountsStep, setDisconnectedAccountsStep] =
    useState<DisconnectedAccountsStep | null>(null)
  const [missingBankStatementsStep, setMissingBankStatementsStep] =
    useState<MissingBankStatementsStep | null>(null)
  const [outstandingTransactionsStep, setOutstandingTransactionsStep] =
    useState<OutstandingTransactionsStep | null>(null)
  const [payrollDocumentsStep, setPayrollDocumentsStep] =
    useState<PayrollDocumentsStep | null>(null)
  const [contractorDocumentsStep, setContractorDocumentsStep] =
    useState<ContractorDocumentsStep | null>(null)
  const [processingFeeDocumentsStep, setProcessingFeeDocumentsStep] =
    useState<ProcessingFeeDocumentsStep | null>(null)
  const [previousYearReturnStep, setPreviousYearReturnStep] =
    useState<PreviousYearReturnStep | null>(null)
  const [assetDepreciationDocumentsStep, setAssetDepreciationDocumentsStep] =
    useState<AssetDepreciationDocumentsStep | null>(null)
  const [otherDocumentsStep, setOtherDocumentsStep] =
    useState<OtherDocumentsStep | null>(null)

  useEffect(() => {
    const findStep = (stepName: EndOfYearAdminReviewStep) =>
      submission?.steps?.find((step) => step.step === stepName)

    if (submission) {
      setFinalReviewStep(
        findStep(EndOfYearAdminReviewStep.finalReview) as FinalReviewStep
      )
      setDisconnectedAccountsStep(
        findStep(
          EndOfYearAdminReviewStep.disconnectedAccounts
        ) as DisconnectedAccountsStep
      )
      setMissingBankStatementsStep(
        findStep(
          EndOfYearAdminReviewStep.uploadMissingStatements
        ) as MissingBankStatementsStep
      )
      setOutstandingTransactionsStep(
        findStep(
          EndOfYearAdminReviewStep.transactions
        ) as OutstandingTransactionsStep
      )
      setPayrollDocumentsStep(
        findStep(EndOfYearAdminReviewStep.docsPayroll) as PayrollDocumentsStep
      )
      setContractorDocumentsStep(
        findStep(
          EndOfYearAdminReviewStep.docsContractorPayments
        ) as ContractorDocumentsStep
      )
      setProcessingFeeDocumentsStep(
        findStep(
          EndOfYearAdminReviewStep.docsProcessingFees
        ) as ProcessingFeeDocumentsStep
      )
      setPreviousYearReturnStep(
        findStep(
          EndOfYearAdminReviewStep.docsPreviousTaxReturn
        ) as PreviousYearReturnStep
      )
      setAssetDepreciationDocumentsStep(
        findStep(
          EndOfYearAdminReviewStep.docsAssetsOver2500
        ) as AssetDepreciationDocumentsStep
      )
      setOtherDocumentsStep(
        findStep(EndOfYearAdminReviewStep.docsOther) as OtherDocumentsStep
      )
    }
  }, [submission])

  return (
    <Container className="eoy-admin__main-content" style={{ width: '100%' }}>
      <Loader loading={loading} />

      <Errors errors={errors} />

      {!loading && user && submission && (
        <>
          <Link
            to={`/admin/finances/records/${user.id}`}
            style={{ color: Colors.blue }} // The `color` prop is not supported by the `Link` component when `to` is set
          >
            &larr; User Record
          </Link>

          <Text
            className="eoy-admin__main-content__header"
            as="h2"
            testId={TEST_IDS.submissionViewHeader}
          >
            Year-End Bookkeeping - {user.firstName} {user.lastName}
          </Text>

          <Divider />

          <SubmissionBanner submission={submission} />

          {finalReviewStep && (
            <EndOfYearReviewGroup
              title="Final Review"
              testId={TEST_IDS.submissionFinalReviewGroup}
              dimmed={
                submission.status === EndOfYearSubmissionStatus.actionRequired
              }
            >
              <FinalReviewSection step={finalReviewStep} userId={user.id} />
            </EndOfYearReviewGroup>
          )}

          <EndOfYearReviewGroup
            title="Bookkeeping"
            testId={TEST_IDS.submissionBookkeepingGroup}
            dimmed={
              submission.status === EndOfYearSubmissionStatus.actionRequired
            }
          >
            <DisconnectedAccountsSection
              step={disconnectedAccountsStep}
              financialAccounts={financialAccounts}
              userId={user.id}
            />
            <MissingBankStatementsSection
              step={missingBankStatementsStep}
              userId={user.id}
            />
            <OutstandingTransactionsSection
              step={outstandingTransactionsStep}
              userId={user.id}
            />
          </EndOfYearReviewGroup>

          <EndOfYearReviewGroup
            title="Documents"
            testId={TEST_IDS.submissionDocumentsGroup}
            prefix={
              <Link
                className="eoy-admin__document-group-documents-link"
                to={`/admin/finances/records/${user.id}?tab=documents`}
                size="small"
              >
                View in Documents tab
              </Link>
            }
            dimmed={
              submission.status === EndOfYearSubmissionStatus.actionRequired
            }
          >
            <PayrollDocumentsSection
              step={payrollDocumentsStep}
              userDocuments={userDocuments}
            />
            <ContractorDocumentsSection
              step={contractorDocumentsStep}
              userDocuments={userDocuments}
            />
            <ProcessingFeeDocumentsSection
              step={processingFeeDocumentsStep}
              userDocuments={userDocuments}
            />
            <PreviousTaxReturnSection
              step={previousYearReturnStep}
              userDocuments={userDocuments}
            />
            <AssetDepreciationDocumentsSection
              step={assetDepreciationDocumentsStep}
              userDocuments={userDocuments}
              depreciableAssets={depreciableAssets}
            />
            <OtherDocumentsSection
              step={otherDocumentsStep}
              userDocuments={userDocuments}
            />
          </EndOfYearReviewGroup>

          <SubmitSection submission={submission} />
        </>
      )}
    </Container>
  )
}

export default MainContent
