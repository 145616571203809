import { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Header, Table } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import { fetchUserDocumentCategoriesIfNeeded } from '../UserDocumentCategories/userDocumentCategories.slice'
import AnnualTaxFilingTableData from './AnnualTaxFilingTableData'
import { fetchAnnualTaxFilingStatusTypesIfNeeded } from '../../Taxes/AnnualTaxes/annualTaxFilingStatusType.slice'
import { fetchAnnualTaxFormTypesIfNeeded } from '../../Taxes/AnnualTaxes/annualTaxFormType.slice'
import { fetchAllUsersIfNeeded } from '../../../actions/admin/adminAllUsersActions'
import AnnualTaxFilingUserSearch from './AnnualTaxFilingUserSearch'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../AnnualTaxDetails/annualTaxDetails.slice'
import {
  Checkbox,
  Dropdown,
  GridRowColumn,
  Loader,
  Pagination,
  Tab,
  Text,
} from '../../../components/BaseComponents'
import {
  getAllAdminUsersById,
  getAllUsersById,
  getIsTaxTeam,
} from '../../../selectors/user.selectors'
import {
  FILTER_TAX_FILING_NO_SERVICES,
  FILTER_TAX_FILING_UNANSWERED,
} from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { RoleName } from '../../../reducers/admin/allRolesReducer'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { useReselector } from '../../../utils/sharedHooks'
import { fetchAllRolesIfNeeded } from '../../../actions/admin/roleActions'
import {
  FETCH_ADMIN_ANNUAL_TAX_FILINGS_KEY,
  fetchAdminAnnualTaxFilings,
} from './adminAnnualTaxFilings.slice'
import { selectAnnualTaxFilingForIds } from './adminAnnualTaxFilings.selector'
import { TaxFormType } from '../../Taxes/AnnualTaxes/Questionnaires/constants'
import { selectAllAnnualTaxDetailsSorted } from '../AnnualTaxDetails/annualTaxDetails.selector'
import { valsToDropdownOptions } from '../../../components/BaseComponents/Dropdown'
import {
  AnnualTaxFilingFormQuestionnaireStatus,
  TaxfyleJobStatus,
  TaxfyleJobStep,
} from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { getIsFetching } from '../../../reducers/fetch'
import { useAppDispatch } from '../../../utils/typeHelpers'
const LIMIT = 50

const dropdownOptions = [
  ...Object.values(TaxFormType),
  FILTER_TAX_FILING_UNANSWERED,
  FILTER_TAX_FILING_NO_SERVICES,
] as const

const taxfyleJobStatusOptions = [...Object.values(TaxfyleJobStatus)]

const taxfyleJobStepOptions = [...Object.values(TaxfyleJobStep)]

const taxChecklistStatusOptions = [
  ...Object.values(AnnualTaxFilingFormQuestionnaireStatus),
]

const mapOptions = (entityArray: UserWithAdminInfo[], entityType: string) => {
  const entities = entityArray.map((entity) => {
    const isDefaultBookkeeperOrManager = entity.roles?.find((role) => {
      return (
        (entityType === RoleName.Bookkeeper &&
          role?.UserRole?.isDefaultBookkeeper) ||
        (entityType === RoleName.AccountManager &&
          role?.UserRole?.isDefaultManager)
      )
    })
    return {
      value: entity?.id,
      text: `${entity.firstName} ${entity.lastName}`,
      key: entity.id,
      description: isDefaultBookkeeperOrManager ? 'default' : '',
    }
  })
  // Allows "all" dropdown option
  entities.push({ value: -1, text: 'all', key: -1, description: '' })
  return entities
}

const subHoursFromNow = (hour: number) =>
  DateTime.now().minus({ hour }).toUTC().toISO()

const timeFilterBuckets = {
  '0-1hr': { max: subHoursFromNow(1) },
  '1-2hr': { max: subHoursFromNow(2), min: subHoursFromNow(1) },
  '2-3hr': { max: subHoursFromNow(3), min: subHoursFromNow(2) },
  '3-6hr': { max: subHoursFromNow(6), min: subHoursFromNow(3) },
  '6-12hr': { max: subHoursFromNow(12), min: subHoursFromNow(6) },
  '12-24hr': { max: subHoursFromNow(24), min: subHoursFromNow(12) },
  '24-48hr': { max: subHoursFromNow(48), min: subHoursFromNow(24) },
  '48+hr': { min: subHoursFromNow(48) },
}

const AnnualTaxFilingTableInformation = ({
  year,
  searchedUserId,
}: {
  searchedUserId?: number
  year: string
}) => {
  const dispatch = useAppDispatch()
  const [totalCount, setTotalCount] = useState(0)

  const [page, setPage] = useState(1)
  const pagesNeeded = Math.ceil(totalCount / LIMIT)
  const [formNeeds, setFormNeeds] = useState<(typeof dropdownOptions)[number]>()
  const [bookkeeperId, setBookkeeperId] = useState<number>()
  const [taxfyleJobStatus, setTaxfyleJobStatus] = useState<TaxfyleJobStatus>()
  const [taxfyleJobStep, setTaxfyleJobStep] = useState<TaxfyleJobStep>()
  const [taxChecklistStatus, setTaxChecklistStatus] =
    useState<AnnualTaxFilingFormQuestionnaireStatus>()
  const [timeInStatus, setTimeInStatus] =
    useState<keyof typeof timeFilterBuckets>()
  const [extensionRequestStatus, setExtensionRequestStatus] =
    useState<AnnualTaxFilingFormQuestionnaireStatus>()
  const [extensionTaxfyleJobStatus, setExtensionTaxfyleJobStatus] =
    useState<TaxfyleJobStatus>()
  const [queriedTaxFilingsIds, setQueriedTaxFilingsIds] = useState<number[]>([])
  const [exempted, setExempted] = useState<boolean | undefined>()

  const isFetching = useReselector(
    getIsFetching,
    FETCH_ADMIN_ANNUAL_TAX_FILINGS_KEY
  )
  const allAdmins = useReselector(getAllAdminUsersById)
  const taxFilings = useReselector(
    selectAnnualTaxFilingForIds,
    queriedTaxFilingsIds
  )
  const allAdminsArray = useMemo(() => Object.values(allAdmins), [allAdmins])

  const bookkeeperOptions = useMemo(() => {
    const bookkeepersArray = allAdminsArray.filter((a) =>
      a.roles?.find((role) => role?.name === RoleName.Bookkeeper)
    )
    return mapOptions(bookkeepersArray, RoleName.Bookkeeper)
  }, [allAdminsArray])

  useEffect(() => {
    setPage(1)
  }, [
    searchedUserId,
    year,
    taxfyleJobStatus,
    taxfyleJobStep,
    taxChecklistStatus,
    bookkeeperId,
    formNeeds,
    timeInStatus,
  ])

  const refreshData = useCallback(async () => {
    const response = await fetchAdminAnnualTaxFilings({
      page,
      limit: LIMIT,
      year,
      userIds: searchedUserId,
      taxfyleJobStatus,
      taxfyleJobStep,
      taxChecklistStatus,
      bookkeeperId,
      formNeeds,
      jobTimeInStatus: timeInStatus
        ? timeFilterBuckets[timeInStatus]
        : undefined,
      extensionRequestStatus,
      extensionTaxfyleJobStatus,
      exempted: exempted || undefined,
    })(dispatch)
    if (response) {
      const { count, filings } = response
      setTotalCount(count)
      setQueriedTaxFilingsIds(filings.map((f) => f.id))
    }
  }, [
    page,
    searchedUserId,
    dispatch,
    year,
    taxfyleJobStatus,
    taxfyleJobStep,
    taxChecklistStatus,
    bookkeeperId,
    formNeeds,
    timeInStatus,
    extensionRequestStatus,
    extensionTaxfyleJobStatus,
    exempted,
  ])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by form:"
              onChange={setFormNeeds}
              options={valsToDropdownOptions(dropdownOptions)}
              value={formNeeds}
              fluid
              placeholder="Filter by Form"
              clearable
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by BK:"
              placeholder="Filter by BK"
              options={bookkeeperOptions}
              value={bookkeeperId}
              fluid
              onChange={setBookkeeperId}
              clearable
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by Job Status:"
              onChange={setTaxfyleJobStatus}
              options={valsToDropdownOptions(taxfyleJobStatusOptions)}
              value={taxfyleJobStatus}
              fluid
              placeholder="Filter by Job Status"
              clearable
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by Job Progression Step:"
              onChange={setTaxfyleJobStep}
              options={valsToDropdownOptions(taxfyleJobStepOptions)}
              value={taxfyleJobStep}
              fluid
              placeholder="Filter by Progression Step"
              clearable
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by Tax Checklist Status:"
              onChange={setTaxChecklistStatus}
              options={valsToDropdownOptions(taxChecklistStatusOptions)}
              value={taxChecklistStatus}
              fluid
              placeholder="Filter by Tax Checklist Status"
              clearable
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by Time in Status/Prog:"
              onChange={setTimeInStatus}
              options={valsToDropdownOptions(Object.keys(timeFilterBuckets))}
              value={timeInStatus}
              fluid
              placeholder="Filter by Time in Status/Prog"
              clearable
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by Extension survey status: "
              onChange={setExtensionRequestStatus}
              options={valsToDropdownOptions(taxChecklistStatusOptions)}
              value={extensionRequestStatus}
              fluid
              placeholder="Filter by Extension survey status"
              clearable
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              label="Filter by extension job status"
              onChange={setExtensionTaxfyleJobStatus}
              options={valsToDropdownOptions(taxfyleJobStatusOptions)}
              value={extensionTaxfyleJobStatus}
              fluid
              placeholder="Filter by extension job status"
              clearable
            />
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn>
          <Checkbox
            onChange={setExempted}
            value={exempted}
            label="Exempted from forced extension"
          />
        </GridRowColumn>
        {/*height set so content doesn't jump*/}
        <Grid.Row style={{ height: 50 }}>
          <Text>
            <b>Total Filtered Count:</b>{' '}
            {isFetching ? <Loader loading /> : totalCount}
          </Text>
        </Grid.Row>
      </Grid>
      <Table striped celled compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Tax Season Kickoff</Table.HeaderCell>
            <Table.HeaderCell>Annual Tax Filing Status</Table.HeaderCell>
            <Table.HeaderCell>Forms</Table.HeaderCell>
            <Table.HeaderCell>
              Taxfyle Status & Job Progression
            </Table.HeaderCell>
            <Table.HeaderCell>Time in Status/Progression</Table.HeaderCell>
            <Table.HeaderCell>Taxfyle Job Link</Table.HeaderCell>
            <Table.HeaderCell>Extension Info</Table.HeaderCell>
            <Table.HeaderCell>Tax Checklist Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {taxFilings.map((filing) => (
            <AnnualTaxFilingTableData
              key={filing.id}
              filingId={filing.id}
              refreshData={refreshData}
              page={page}
              taxYear={year}
            />
          ))}
        </Table.Body>
      </Table>
      {totalCount > LIMIT && (
        <Pagination
          currentPage={page}
          onPageClick={setPage}
          pages={pagesNeeded}
        />
      )}
    </>
  )
}

const TaxFilingsTable = () => {
  const dispatch = useAppDispatch()
  const allUsers = useReselector(getAllUsersById)
  const [searchedUserId, setSearchedUserId] = useState<number>()
  const isTaxTeam = useReselector(getIsTaxTeam)

  const allAnnualTaxDetails = useReselector(selectAllAnnualTaxDetailsSorted)

  useEffect(() => {
    dispatch(fetchAllUsersIfNeeded())
    dispatch(fetchAnnualTaxFilingStatusTypesIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
    dispatch(fetchAnnualTaxFormTypesIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAllRolesIfNeeded())
  }, [dispatch])

  const panes = useMemo(
    () =>
      allAnnualTaxDetails.map(({ taxYear }) => ({
        menuItem: `${taxYear} Tax Year`,
        render: () => (
          <AnnualTaxFilingTableInformation
            year={taxYear}
            searchedUserId={searchedUserId}
          />
        ),
      })),
    [allAnnualTaxDetails, searchedUserId]
  )

  return (
    <>
      <Header as="h3">Annual Tax Filings</Header>
      {isTaxTeam && (
        <div>
          <div className="flexbox">
            <AnnualTaxFilingUserSearch
              allUsers={allUsers}
              searchedUserId={searchedUserId}
              setSearchedUserId={setSearchedUserId}
            />
          </div>
          <Divider />
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
      )}
      {!isTaxTeam && (
        <div>
          Sorry, only folks on the Tax Team or administrators are allowed to
          view this page
        </div>
      )}
    </>
  )
}

export default TaxFilingsTable
