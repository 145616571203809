import { Grid, Divider } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { NavButtons } from '../../end-of-year-review/nav-buttons'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  Card,
  GridRowColumn,
  Text,
  Alert,
  Icon,
} from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import DeleteDocumentConfirm from '../../../UserDocuments/sharedComponents/Modals/DeleteDocumentConfirm'
import { MissingStatementCard } from '../../../../components/Finances/Accounts/MissingStatementCard'
import { useNavigate, useParams } from 'react-router-dom'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import styles from './styles.module.scss'
import { useViewMissingStatementsList } from '../../../../components/shared/ViewMissingStatementsAccordion'
import {
  getMissingStatements,
  MissingStatement,
} from '../../../UserDocuments/userDocuments.slice'
import { useFetchResponse } from '../../../../utils/sharedHooks'

export const FollowUpOnStatements = () => {
  const { year } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [missingStatements, setMissingStatements] = useState<
    MissingStatement[] | undefined
  >()
  const [isMissingStatements, setIsMissingStatements] = useState<boolean>(false)

  const fetchingStatements = useFetchResponse(getMissingStatements)
  useEffect(() => {
    const filterStatementsByYear = (statements: string[], year: number) => {
      return statements.filter((statement) => {
        const statementDate = new Date(statement)
        return statementDate.getFullYear() === year
      })
    }
    const statements = fetchingStatements || []

    const statementsFiltered = statements.map((statement) => ({
      ...statement,
      statements: statement.statements
        ? filterStatementsByYear(
            statement.statements as string[],
            parseInt(year ?? '')
          )
        : [],
    }))

    const filteredAccount = []
    for (const statement of statementsFiltered) {
      if (statement.statements.length > 0) {
        filteredAccount.push(statement)
      }
    }
    const filteredStatements = filteredAccount

    setMissingStatements(filteredStatements)
    if (filteredStatements && filteredStatements?.length > 0) {
      setIsMissingStatements(true)
    }
  }, [fetchingStatements, year])

  const formattedStatements = useViewMissingStatementsList({
    neededStatements: missingStatements,
  })

  const setStatementMap = async () => {
    const filterStatementsByYear = (statements: string[], year: number) => {
      return statements.filter((statement) => {
        const statementDate = new Date(statement)
        return statementDate.getFullYear() === year
      })
    }
    const fetchingStatements = (await getMissingStatements()(dispatch)) || []
    const statementsFiltered = fetchingStatements.map((statement) => ({
      ...statement,
      statements: filterStatementsByYear(
        statement.statements,
        parseInt(year ?? '')
      ),
    }))
    const filteredAccount = []
    for (const statement of statementsFiltered) {
      if (statement.statements.length > 0) {
        filteredAccount.push(statement)
      }
    }
    const filteredStatements = filteredAccount
    setMissingStatements(filteredStatements)
    if (filteredStatements?.length === 0) {
      setIsMissingStatements(false)
    }
  }

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon
            name="check"
            style={{ color: '#457634' }}
            icon={solid('check')}
          />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>
      <Grid>
        <GridRowColumn centerContent className="header-container">
          <StepHeaders
            imageSrc="https://heard-images.s3.amazonaws.com/assets/upload-statement.png"
            imageAlt="uploadStatement"
            imageWidth={180}
            imageHeight={180}
            title="Upload missing bank statements"
            description=""
            customColor="green"
          >
            <Grid>
              <GridRowColumn className="align-left">
                <Text>
                  We need these to reconcile your books. Please upload all the
                  statements listed below.
                </Text>
              </GridRowColumn>
              <GridRowColumn className="align-left">
                {formattedStatements}
              </GridRowColumn>
              <Grid.Row />
              <GridRowColumn className={styles.container}>
                <MissingStatementCard setStatementMap={setStatementMap} />
              </GridRowColumn>
              {(missingStatements ?? []).length > 0 && (
                <GridRowColumn width={12} className="tooltip-grid">
                  <Alert
                    title="Note from Bookkeeper"
                    style={{ width: '520px', padding: '40px' }}
                    customIcon="none"
                    contentStyle={{ textAlign: 'left' }}
                    type="acknowledge"
                  >
                    <Text style={{ color: '#AC590C', marginTop: '20px' }}>
                      TBD
                    </Text>
                  </Alert>
                </GridRowColumn>
              )}
              {(missingStatements ?? []).length < 1 && (
                <Card backgroundColor="lightGreen" fullWidth>
                  <div className={styles.allUploaded}>
                    <Icon icon={solid('check')} color="accentGreen" size="2x" />

                    <div className={styles.uploadedText}>
                      <Text as="h3">All statements uploaded</Text>
                      <Text>
                        Thanks! You’ve uploaded all missing statements.
                      </Text>
                    </div>
                  </div>
                </Card>
              )}
            </Grid>
          </StepHeaders>
        </GridRowColumn>
        <GridRowColumn>
          <div className="alert-footer">
            <Alert
              title="Why is this important?"
              style={{ width: '520px' }}
              customIcon={<Icon icon={solid('info-circle')} />}
              contentStyle={{ textAlign: 'left' }}
            >
              Reconciliation is a standard practice to ensure all transactions
              are accounted for. We use your bank statements to check that your
              balances match what we have in your books.
            </Alert>
          </div>
        </GridRowColumn>
        <DeleteDocumentConfirm />
      </Grid>
      <Divider style={{ marginTop: 50 }} />

      <NavButtons
        isStepComplete={!isMissingStatements}
        stepBack={() => {
          navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
        }}
        stepForward={() => {
          navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
        }}
        saveAndExit={() => {
          navigate('/taxes/annual')
        }}
      />
    </div>
  )
}
