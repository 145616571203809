import Text from '../../../../components/BaseComponents/Text'
import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { GridRowColumn } from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import { ReviewStepCard } from '../../../../components/Finances/ReviewStep'
import { useNavigate, useParams } from 'react-router-dom'
import { IStep } from '../step-map'
import './styles.scss'

export const FollowUp = ({
  setIsStepComplete,
}: {
  setIsStepComplete: (value: boolean) => void
}) => {
  const { year } = useParams()
  const navigate = useNavigate()

  const [isPendingBankStatements, setIsPendingBankStatements] =
    useState<boolean>(false)
  const [isPendingTransaction, setIsPendingTransaction] =
    useState<boolean>(false)
  const [isPendingDocuments, setIsPendingDocuments] = useState<boolean>(false)

  const followUpStep: IStep = {
    id: 'follow-up-transactions',
    name: 'Review "Uncategorized" transactions',
    route: 'follow-up-transactions',
    component: null,
    props: null,
    isAlwaysComplete: true,
    isRequired: () => true,
  }

  const statementsStep: IStep = {
    id: 'follow-up-statements',
    name: 'Upload missing statements',
    route: 'follow-up-statements',
    component: null,
    props: null,
    isAlwaysComplete: true,
    isRequired: () => true,
  }

  const yearEndStep: IStep = {
    id: 'follow-up-documents',
    name: 'Upload year-end documents',
    route: 'follow-up-documents',
    component: null,
    props: null,
    isAlwaysComplete: true,
    isRequired: () => true,
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const enablePendings = () => {
    setIsPendingBankStatements(true)
    setIsPendingTransaction(true)
    setIsPendingDocuments(true)
  }

  const setReviewIndex = (route: string) => {
    navigate(`/taxes/annual/review-and-close-books/${year}/${route}`)
  }

  useEffect(() => {
    if (
      !isPendingBankStatements &&
      !isPendingTransaction &&
      !isPendingDocuments
    ) {
      setIsStepComplete(true)
    }
  }, [
    isPendingBankStatements,
    isPendingTransaction,
    isPendingDocuments,
    setIsStepComplete,
  ])

  return (
    <StepHeaders
      imageSrc="https://heard-images.s3.amazonaws.com/assets/bookkeeping.svg"
      imageAlt="coins"
      imageWidth={180}
      imageHeight={180}
      title="Follow-up on your books"
      description=""
      customColor="yellow"
    >
      <Grid>
        <GridRowColumn className="align-left">
          <Text>
            The bookkeeping team has marked these items as needing additional
            follow-up from you. Please complete them ASAP so we can complete
            your end-of-year bookkeeping soon.
          </Text>
        </GridRowColumn>
        <GridRowColumn />
      </Grid>
      <div className="group-text-card">
        <Text>CLOSE YOUR BOOKS</Text>
      </div>
      <ReviewStepCard
        key={'follow-up-transactions'}
        step={followUpStep}
        setReviewIndex={setReviewIndex}
        isPending={isPendingTransaction}
        pendingText="The bookkeeping team couldn’t categorize some of your transactions. Please add a note to provide more context."
      />
      <ReviewStepCard
        key={'follow-up-statements'}
        step={statementsStep}
        setReviewIndex={setReviewIndex}
        isPending={isPendingBankStatements}
        pendingText="Some of your bank statements were missing or incorrect. Please upload the correct statements."
      />
      <div className="group-text-card">
        <Text>YEAR-END DOCUMENTS</Text>
      </div>
      <ReviewStepCard
        key={'follow-up-documents'}
        step={yearEndStep}
        setReviewIndex={setReviewIndex}
        isPending={isPendingDocuments}
        pendingText="Some of your year-end documents were missing or incorrect. Please upload the correct documents."
      />
    </StepHeaders>
  )
}
