import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  TaxListOptionId,
  TaxListQuestionId,
  useTaxListQuestionRes,
} from '../../service'
import {
  Card,
  FormikRadioToggleButton,
  getFieldNames,
  GridColumnSpacer,
  GridRowColumn,
  Icon,
  Link,
  Text,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { formatCurrencyFromCents } from '../../../../../../utils/currencyHelpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import SkipQuestion, {
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { tqBigSpace } from '../../helpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

export const educationQuestionIds = [TaxListQuestionId.education_credit_type]

const ATOCMaxCredit = formatCurrencyFromCents(250000, {
  hideDecimalsIfZero: true,
})
const ATOCRemainingCredit = formatCurrencyFromCents(100000, {
  hideDecimalsIfZero: true,
})

const LLCMaxCredit = formatCurrencyFromCents(200000, {
  hideDecimalsIfZero: true,
})

const EducationPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const educationCreditQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.education_credit_type,
    formId,
  })

  const skippedQuestions = useSkipQuestion(educationQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.education_credit_type]:
        educationCreditQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responses = [educationCreditQR.getResponseData(values)]
      await goToNextStep(responses, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.education],
      })
    },
  })

  const { values, submitForm, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  const need1098T = Boolean(
    Array.isArray(values[TaxListQuestionId.education_credit_type]) &&
      values[TaxListQuestionId.education_credit_type].length
  )

  return (
    <FormikProvider value={formik}>
      <Grid>
        <Grid.Row />
        <Grid.Row />
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Education Credits
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            If you, your spouse or your dependent are attending a qualifying
            institution, you may be eligible for one or both of these credits. A{' '}
            <b>1098-T</b> will help us determine how much credit you can claim.
          </Text>
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={3} only="computer" />
          <Grid.Column computer={5} mobile={16} tablet={16} stretched>
            <Card backgroundColor="stone40" style={{ display: 'flex' }}>
              <Text as="h1" textAlign="center">
                American Opportunity Tax Credit
              </Text>
              <br />
              <Text as="bodyLg">
                The <b>American Opportunity Tax Credit (AOTC)</b> is a credit
                for qualified education expenses paid for an{' '}
                <b>
                  eligible student for the first four years of higher education.
                </b>
              </Text>
              <Text>
                <ul>
                  <li>
                    You can get a maximum annual credit of {ATOCMaxCredit} per
                    eligible student.
                  </li>
                  <li>
                    If the credit brings the amount of tax you owe to zero, you
                    can have 40 percent of any remaining amount of the credit
                    (up to {ATOCRemainingCredit}) refunded to you.
                  </li>
                </ul>
              </Text>
              <Link
                href="https://www.irs.gov/credits-deductions/individuals/aotc"
                newPage
              >
                Am I eligible for this credit?
                <Icon
                  icon={light('square-arrow-up-right')}
                  style={{ marginLeft: 6 }}
                />
              </Link>
              <div style={{ flex: 1, minHeight: 20 }} />
              <FormikRadioToggleButton
                fullWidth
                name={fieldNames[TaxListQuestionId.education_credit_type]}
                value={TaxListOptionId.american_opportunity_credit}
                multiSelect
              >
                I may qualify
              </FormikRadioToggleButton>
            </Card>
          </Grid.Column>
          <GridColumnSpacer tablet mobile />
          <Grid.Column computer={5} mobile={16} tablet={16} stretched>
            <Card backgroundColor="stone40" style={{ display: 'flex' }}>
              <Text as="h1" textAlign="center">
                Lifetime Learning Credit
              </Text>
              <br />
              <Text as="bodyLg">
                The <b>Lifetime Learning Credit (LLC)</b> is for qualified
                tuition and related expenses paid for{' '}
                <b>
                  eligible students enrolled in an eligible educational
                  institution.
                </b>
              </Text>
              <Text>
                <ul>
                  <li>
                    There&apos;s no limit on the number of years you can claim
                    the credit.
                  </li>
                  <li>
                    The credit is worth up to {LLCMaxCredit} per tax return.
                  </li>
                </ul>
              </Text>
              <Link
                href="https://www.irs.gov/credits-deductions/individuals/llc"
                newPage
              >
                Am I eligible for this credit?
                <Icon
                  icon={light('square-arrow-up-right')}
                  style={{ marginLeft: 6 }}
                />
              </Link>
              <div style={{ flex: 1, minHeight: 20 }} />
              <FormikRadioToggleButton
                fullWidth
                name={fieldNames[TaxListQuestionId.education_credit_type]}
                value={TaxListOptionId.lifetime_learning_credit}
                multiSelect
              >
                I may qualify
              </FormikRadioToggleButton>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn {...tqBigSpace} short>
          <SkipQuestion questionId={TaxListQuestionId.education_credit_type} />
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Divider />
        </GridRowColumn>
        <UploadDocumentSubSection
          categories={[
            need1098T ? UserDocumentCategoryIdentifier.form1098t : undefined,
          ]}
        />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default EducationPanel
