import { useMemo } from 'react'
import { uniq } from 'lodash'
import { useFormik, FormikProvider } from 'formik'
import { Divider, Grid } from 'semantic-ui-react'

import { Form1040DetailsProps } from '.'
import {
  GridRowColumn,
  Text,
  FormikDropdown,
  makeStringArraySchema,
  getFieldNames,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { TaxListOptionId, TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const locationInfoQuestionIds = [
  TaxListQuestionId.states_live_and_earned,
  TaxListQuestionId.states_with_clients,
  TaxListQuestionId.states_spouse,
]

const LocationInfoPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
  taxYear,
}: Form1040DetailsProps) => {
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const questionStatesEarned = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.states_live_and_earned,
    taxYear
  )
  const responseStatesEarned = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.states_live_and_earned,
    form1040?.id
  )
  const questionStatesWithClients = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.states_with_clients,
    taxYear
  )
  const responseStatesWithClients = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.states_with_clients,
    form1040?.id
  )
  const questionStatesSpouse = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.states_spouse,
    taxYear
  )
  const responseStatesSpouse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.states_spouse,
    form1040?.id
  )
  const responseFilingStatus = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.filing_status,
    form1040?.id
  )

  // Derived
  const responseActiveStates = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.states_active,
    form1040?.id
  )
  const responseNumberActiveStates = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_states_active,
    form1040?.id
  )

  const needsSpouseInfo = useMemo(() => {
    return (
      responseFilingStatus?.[0]?.value ===
        TaxListOptionId.married_filing_jointly ||
      responseFilingStatus?.[0]?.value === TaxListOptionId.qualifying_widow
    )
  }, [responseFilingStatus])

  const formik = useFormik({
    initialValues: {
      statesEarned: responseStatesEarned?.[0]?.value,
      statesWithClients: responseStatesWithClients?.[0]?.value,
      statesSpouse: responseStatesSpouse?.[0]?.value,
    },
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (values.statesEarned !== responseStatesEarned?.[0]?.value) {
        responseData.push({
          id: responseStatesEarned?.[0]?.id,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.states_live_and_earned,
          value: values.statesEarned,
        })
      }
      if (values.statesWithClients !== responseStatesWithClients?.[0]?.value) {
        responseData.push({
          id: responseStatesWithClients?.[0]?.id,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.states_with_clients,
          value: values.statesWithClients,
        })
      }
      if (
        needsSpouseInfo &&
        (!Array.isArray(values.statesSpouse) ||
          values.statesSpouse !== responseStatesSpouse?.[0]?.value)
      ) {
        const userStates: string[] = []
        if (Array.isArray(values.statesEarned)) {
          userStates.push(...values.statesEarned)
        }
        if (Array.isArray(values.statesWithClients)) {
          userStates.push(...values.statesWithClients)
        }
        const uniqueUserStates = uniq(userStates)
        const spouseStates = Array.isArray(values.statesSpouse)
          ? values.statesSpouse
          : uniqueUserStates
        if (spouseStates.length > 0) {
          responseData.push({
            id: responseStatesSpouse?.[0]?.id,
            annualTaxFilingFormId: form1040?.id,
            questionId: TaxListQuestionId.states_spouse,
            value: spouseStates,
          })
        }
      }
      const allStates: string[] = []
      if (Array.isArray(values.statesEarned)) {
        allStates.push(...values.statesEarned)
      }
      if (Array.isArray(values.statesWithClients)) {
        allStates.push(...values.statesWithClients)
      }
      if (Array.isArray(values.statesSpouse)) {
        allStates.push(...values.statesSpouse)
      }
      const uniqueStates = uniq(allStates)
      responseData.push({
        id: responseActiveStates?.[0]?.id,
        annualTaxFilingFormId: form1040?.id,
        questionId: TaxListQuestionId.states_active,
        value: uniqueStates,
      })
      responseData.push({
        id: responseNumberActiveStates?.[0]?.id,
        annualTaxFilingFormId: form1040?.id,
        questionId: TaxListQuestionId.number_states_active,
        value: uniqueStates.length,
      })
      await goToNextStep(responseData, nextScreen ?? null)
    },
  })

  const { isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Update Location Information
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            Please update the following information for {taxYear}. These answers
            will appear on your tax return.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikDropdown
            search
            name={fieldNames.statesEarned}
            label={questionStatesEarned.question?.text}
            // sort by text
            options={questionStatesEarned?.options
              ?.map((o) => ({
                text: o.text,
                value: o.id,
                key: o.id,
              }))
              .sort((a, b) => a.text.localeCompare(b.text))}
            schema={makeStringArraySchema()}
            multiple
            clearable
            fullWidth
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikDropdown
            search
            name={fieldNames.statesWithClients}
            label={questionStatesWithClients.question?.text}
            options={questionStatesWithClients?.options
              ?.map((o) => ({
                text: o.text,
                value: o.id,
                key: o.id,
              }))
              .sort((a, b) => a.text.localeCompare(b.text))}
            schema={makeStringArraySchema()}
            multiple
            clearable
            fullWidth
          />
        </GridRowColumn>
        {needsSpouseInfo && (
          <GridRowColumn {...tqSmallSpace}>
            <FormikDropdown
              search
              name={fieldNames.statesSpouse}
              label={questionStatesSpouse.question?.text}
              options={questionStatesSpouse?.options
                ?.map((o) => ({
                  text: o.text,
                  value: o.id,
                  key: o.id,
                }))
                .sort((a, b) => a.text.localeCompare(b.text))}
              schema={makeStringArraySchema({ required: false, min: 0 })}
              multiple
              clearable
              fullWidth
            />
          </GridRowColumn>
        )}
        <GridRowColumn {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>

        <FormFlowFooter
          loading={isSubmitting}
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default LocationInfoPanel
