import { useParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Divider, Grid } from 'semantic-ui-react'

import { DeductionsAndCreditsStepProps } from '.'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { skipQuestionId, useSkipQuestion } from '../../Shared/SkipQuestion'
import { TQYesNoResponse } from '../../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const ownedHomeQuestionIds = [
  TaxListQuestionId.paid_mortgage_interest,
  TaxListQuestionId.refinanced_home,
  TaxListQuestionId.eco_home_improvements,
  TaxListQuestionId.paid_property_taxes,
]
const OwnedHomePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)

  const paidMortgageInterestQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.paid_mortgage_interest,
    formId,
  })

  const refinancedHomeQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.refinanced_home,
    formId,
  })

  const ecoHomeImprovementsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.eco_home_improvements,
    formId,
  })

  const paidPropertyTaxesQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.paid_property_taxes,
    formId,
  })

  const skippedQuestions = useSkipQuestion(ownedHomeQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.paid_mortgage_interest]:
        paidMortgageInterestQR.initialResponse,
      [TaxListQuestionId.refinanced_home]: refinancedHomeQR.initialResponse,
      [TaxListQuestionId.eco_home_improvements]:
        ecoHomeImprovementsQR.initialResponse,
      [TaxListQuestionId.paid_property_taxes]:
        paidPropertyTaxesQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData = [
        paidMortgageInterestQR.getResponseData(values),
        refinancedHomeQR.getResponseData(values),
        ecoHomeImprovementsQR.getResponseData(values),
        paidPropertyTaxesQR.getResponseData(values),
      ]

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.ownedHome],
      })
    },
  })

  const { values, isSubmitting, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Home Owner
          </Text>
        </GridRowColumn>
        <TQYesNoResponse questionResponse={paidMortgageInterestQR} />
        <TQYesNoResponse questionResponse={refinancedHomeQR} skippable />
        <TQYesNoResponse questionResponse={ecoHomeImprovementsQR} skippable />
        <TQYesNoResponse questionResponse={paidPropertyTaxesQR} skippable />

        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>

        <UploadDocumentSubSection
          categories={[
            values[TaxListQuestionId.paid_property_taxes]
              ? UserDocumentCategoryIdentifier.propertyTaxDocuments
              : undefined,
            values[TaxListQuestionId.refinanced_home] ||
            values[TaxListQuestionId.paid_mortgage_interest]
              ? UserDocumentCategoryIdentifier.form1098
              : undefined,
          ]}
        />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default OwnedHomePanel
